import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useBroadcastChannel } from "use-broadcast-channel";

import {
  StackTemplateCategory,
  getListStackTemplatesQueryKey,
  useCreateStacksFromTemplate,
  useListStackTemplatesSuspense,
} from "@/generated";
import useLoading from "@/hooks/use-loading";
import { useAppSchemeURL } from "@/lib/utils";

import TemplateGrid from "../components/template/template-grid";

export default function CreateFromTemplatesPage() {
  const queryClient = useQueryClient();
  const { showLoading, hideLoading } = useLoading();
  const resetStacksCache = useBroadcastChannel("stacks:reset-cache");
  const [stackId, setStackId] = useState(0);
  const appSchemeURL = useAppSchemeURL(`/stacks/${stackId}`);

  const { data: templates } = useListStackTemplatesSuspense<StackTemplateCategory[]>({
    group_by_category: true,
  });

  const { mutate: createStacksFromTemplate, isPending } = useCreateStacksFromTemplate({
    mutation: {
      onSuccess: async (res) => {
        queryClient.invalidateQueries({ queryKey: getListStackTemplatesQueryKey() });
        resetStacksCache("");
        hideLoading();
        setStackId(res[0].id);
      },
    },
  });

  useEffect(() => {
    if (stackId > 0) {
      window.location.href = appSchemeURL;
    }
  }, [stackId, appSchemeURL]);

  const onCreateTemplate = (selectedTemplates: string[]) => {
    if (isPending) {
      return;
    }
    showLoading();
    createStacksFromTemplate({
      data: {
        templates: selectedTemplates,
      },
    });
  };

  return (
    <div className="relative flex-1 pb-20">
      <TemplateGrid templates={templates} selectedTemplates={[]} onSelectedTemplatesChange={onCreateTemplate} />
    </div>
  );
}
