import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import posthog from "posthog-js";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import "@/lib/firebase-track.ts";

import App from "./app.tsx";
import "./index.css";
import AccessTokenProvider from "./jsbridge/access-token-provider.tsx";

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

if (import.meta.env.PROD) {
  posthog.init("phc_bK48RdGXEB4V0Msplamlfg0pLE09gl9vywD0knAX6lw", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "never",
    capture_pageview: false,
    autocapture: false,
    disable_session_recording: true,
  });
}

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://ff24973fef06868627583506a3bc5744@o4505185715748864.ingest.us.sentry.io/4507758820261888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: [/^\/api\//, /^https:\/\/api\.stackie\.ai\//],
});

document.documentElement.addEventListener("dragstart", (event) => {
  event.preventDefault();
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <AccessTokenProvider>
        <App />
      </AccessTokenProvider>
    </Suspense>
  </React.StrictMode>,
);
