import { useEffect, useState } from "react";

export interface AlertInfo {
  title?: string;
  message: string;
  actions: AlertAction[];
}

export interface AlertAction {
  title: string;
  style?: "cancel" | "destructive";
}

export interface Notification {
  name: string;
}

interface JSBridge {
  getUserToken(): Promise<string>;
  closePage(): Promise<void>;
  vibrate(): Promise<void>;
  showLoading(): Promise<void>;
  hideLoading(): Promise<void>;
  alert(info: AlertInfo): Promise<number>;
  toast(message: string): Promise<void>;
  pickImage(params: string): Promise<string[]>;
  isPickImageAvailable(): Promise<boolean>;
  isTurnIntoStackAvailable(): Promise<boolean>;
  changeStack(params: string): Promise<boolean>;
  turnIntoStack(params: string): Promise<boolean>;
  postNotification(params: string): Promise<void>;
}

async function waitForEvent(eventName: string): Promise<void> {
  return new Promise((resolve) => {
    document.addEventListener(eventName, () => {
      resolve();
    });
  });
}

export async function getJSBridge(): Promise<JSBridge | null> {
  if (typeof window === "undefined") {
    return null;
  }

  if (!("WebViewJavascriptBridge" in window)) {
    await waitForEvent("WebViewJavascriptBridgeReady");
  }

  if (!("OABridge" in window)) {
    throw new Error("OABridge not found");
  }

  return createJSBridgeProxy(window.OABridge);
}

function createJSBridgeProxy(oaBridge: any): JSBridge {
  return {
    getUserToken: async () => {
      return new Promise((resolve) => {
        oaBridge.getUserToken(null, resolve);
      });
    },

    closePage: async () => {
      return new Promise((resolve) => {
        oaBridge.closePage(null, resolve);
      });
    },

    vibrate: async () => {
      return new Promise((resolve) => {
        oaBridge.vibrate(null, resolve);
      });
    },

    alert: async (info: AlertInfo) => {
      return new Promise((resolve) => {
        oaBridge.alert(JSON.stringify(info), resolve);
      });
    },

    showLoading: async () => {
      return new Promise((resolve) => {
        oaBridge.showLoading(null, resolve);
      });
    },

    hideLoading: async () => {
      return new Promise((resolve) => {
        oaBridge.hideLoading(null, resolve);
      });
    },

    toast: async (message: string) => {
      return new Promise((resolve) => {
        oaBridge.toast(message, resolve);
      });
    },

    pickImage: async (message: string) => {
      return new Promise((resolve) => {
        oaBridge.pickImage(message, resolve);
      });
    },

    isPickImageAvailable: async () => {
      return new Promise((resolve) => {
        oaBridge.pickImage ? resolve(true) : resolve(false);
      });
    },

    changeStack: async (params: string) => {
      return new Promise((resolve) => {
        oaBridge.changeStack(params, resolve);
      });
    },

    isTurnIntoStackAvailable: async () => {
      return new Promise((resolve) => {
        oaBridge.turnIntoStack ? resolve(true) : resolve(false);
      });
    },

    turnIntoStack: async (params: string) => {
      return new Promise((resolve) => {
        oaBridge.turnIntoStack(params, resolve);
      });
    },

    postNotification: async (params: string) => {
      return new Promise((resolve) => {
        oaBridge.postNotification(params, resolve);
      });
    },
  };
}

export function useJSBridge() {
  const [jsBridge, setJSBridge] = useState<JSBridge | null>(null);

  useEffect(() => {
    (async () => {
      const bridge = await getJSBridge();
      if (bridge) {
        setJSBridge(bridge);
      }
    })();
  }, []);

  return jsBridge;
}
