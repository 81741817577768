import LoadingIndicator from "./app/components/loading-indicator";
import SafeArea from "./app/components/safe-area";

export default function Loading() {
  return (
    <div className="fixed inset-0 flex">
      <SafeArea className="grid flex-1 place-items-center">
        <LoadingIndicator />
      </SafeArea>
    </div>
  );
}
