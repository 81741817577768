import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import localforage from "localforage";
import { RouterProvider } from "react-router-dom";

import PersistGate from "./app/components/persist-gate";
import { useAccessToken } from "./jsbridge/access-token-provider";
import router from "./router";

const cacheStore = localforage.createInstance({
  name: "react-query-cache",
  driver: localforage.LOCALSTORAGE,
});

const persister = createAsyncStoragePersister({
  storage: cacheStore,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: Infinity,
    },
  },
});

broadcastQueryClient({ queryClient });

export default function App() {
  const accessToken = useAccessToken();

  // increase the number when the data structure changes
  // to burst the cache
  const buster = `1:${accessToken}`;

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister, buster }}>
      <PersistGate>
        <RouterProvider router={router} />
      </PersistGate>
    </PersistQueryClientProvider>
  );
}
