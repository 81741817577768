import dayjs from "dayjs";
import { Ref, forwardRef } from "react";

export interface DateTimePickerProps {
  value: string;
  onChange: (value: string) => void;
}

export default forwardRef(function DateTimePicker(
  { value, onChange }: DateTimePickerProps,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <input
      ref={ref}
      className="h-[36px] w-full appearance-none rounded-md border border-[rgba(9,9,9,0.10)] bg-white px-[6px] py-[8px] text-base leading-5 text-black focus:outline-none date-and-time-value:text-start"
      type="datetime-local"
      value={dayjs(value).local().format("YYYY-MM-DDTHH:mm:ss")}
      onChange={(e) => onChange(dayjs(e.target.value).toISOString())}
    />
  );
});
