import { useEffect, useState } from "react";

import Icon from "@/app/components/icon";
import { CreateStackRequestInputPropertiesItem, createStack, draftStack } from "@/generated";
import useLoading from "@/hooks/use-loading";
import useToast from "@/hooks/use-toast";
import { useAppSchemeURL } from "@/lib/utils";

import NavigationBar from "../components/navigationbar";
import TextArea from "../components/textarea";

export default function NewStackPage() {
  const { showLoading, hideLoading } = useLoading();
  const [prompt, setPrompt] = useState("");
  const { showToast } = useToast();
  const [stackId, setStackId] = useState(0);
  const appSchemeURL = useAppSchemeURL("/stacks/" + stackId);
  const defaultPrompt =
    "Note my daily cardio record.\nI want to log: sports(hiking, swimming, etc.), duration, length, steps, and my feeling. ";

  useEffect(() => {
    if (stackId) {
      window.location.href = appSchemeURL;
    }
  }, [stackId, appSchemeURL]);
  const onGenerate = async () => {
    try {
      showLoading();
      const res = await draftStack({
        prompt: prompt || defaultPrompt,
      });
      const stack = await createStack({
        name: res.name,
        rename_on_duplicate: true,
        prompt: res.prompt,
        properties: res.properties as CreateStackRequestInputPropertiesItem[],
        emoji: res.emoji,
        theme_color: res.theme_color,
      });
      hideLoading();
      setStackId(stack.id);
    } catch (error: any) {
      showToast(error.response.data.message);
      hideLoading();
    }
  };

  return (
    <div>
      <NavigationBar title="New Stack" />

      <div className="flex w-full flex-1 flex-col justify-start gap-4 bg-[#f2f2f2] p-4">
        <TextArea
          height={148}
          maxLength={200}
          value={prompt}
          onChange={(value) => setPrompt(value)}
          label="Chat to draft a stack"
          icon={<Icon name="Star" />}
          placeholder={defaultPrompt}
        />
        <div
          onClick={onGenerate}
          className="flex flex-col items-center justify-center gap-1 rounded-xl px-4 py-3 text-white [background:#000]"
        >
          Generate
        </div>
      </div>
    </div>
  );
}
