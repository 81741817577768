import { useNavigate } from "react-router-dom";

import { useJSBridge } from "@/jsbridge";

export function useGoBack() {
  const jsbridge = useJSBridge();
  const navigator = useNavigate();
  const goBack = () => {
    if (jsbridge) {
      jsbridge.closePage();
    } else {
      navigator(-1);
    }
  };
  return { goBack };
}
