import React from "react";
import { useNavigate } from "react-router-dom";

import Icon from "@/app/components/icon";
import useGoogleTracking from "@/hooks/use-google-tracking";
import { useJSBridge } from "@/jsbridge";

interface NavbarProps {
  title?: string;
  rightContent?: React.ReactNode;
  rightContentAsButton?: boolean; // Workaround... TODO: Should not be defaults to button
  useJSBridgeForClose?: boolean;
  onRightClick?: () => void;
}

const NavigationBar: React.FC<NavbarProps> = ({
  title,
  rightContent,
  useJSBridgeForClose = true,
  rightContentAsButton = true,
  onRightClick,
}) => {
  const jsBridge = useJSBridge();
  const navigate = useNavigate();
  const { trackCustomEvent } = useGoogleTracking();
  const closePage = () => {
    if (useJSBridgeForClose) {
      jsBridge?.closePage();
    } else {
      navigate(-1); // 返回上一页
    }
    trackCustomEvent("return_button_click");
  };

  return (
    <div className="main-container fixed inset-x-0 top-0 z-10 mx-auto my-0 w-full flex-nowrap border-b border-solid border-b-[#cbcbcb] bg-[#fff]">
      <div className="mt-[env(safe-area-inset-top)] flex h-[--navigation-bar-height] items-center justify-between px-4">
        <button onClick={closePage} className="flex flex-1 items-center">
          <Icon name="ArrowLeft" width={24} height={24} />
        </button>
        <div className="flex-[3] text-center">
          {title && (
            <p className="text-center text-base font-[590] leading-5 tracking-[-0.4px] text-[color:var(--Pure-Black,#000)]">
              {title}
            </p>
          )}
        </div>
        <div className="flex flex-1 justify-end">
          {rightContent ? (
            rightContentAsButton ? (
              <button onClick={onRightClick} className="flex items-end">
                {rightContent}
              </button>
            ) : (
              <div className="flex items-end">{rightContent}</div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
