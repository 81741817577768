import { useCallback, useEffect, useState } from "react";

import { UserProfile } from "@/generated";
import { useJSBridge } from "@/jsbridge";
import request from "@/services/mutator/custom-instance";

export default function useUserInfo() {
  const [userId, setUserId] = useState(localStorage.getItem("sub") || "");
  const [accessToken] = useState(localStorage.getItem("access_token"));
  const jsBridge = useJSBridge();

  const initUser = useCallback(async () => {
    if (!jsBridge) {
      return;
    }

    const token = await jsBridge.getUserToken();

    if ((token && token !== accessToken) || !userId) {
      getUserInfo();
    }
  }, [accessToken, jsBridge, userId]);

  useEffect(() => {
    initUser();
  }, [initUser]);

  const getUserInfo = async () => {
    const res: UserProfile = await request({
      url: "/oauth/userinfo",
      method: "get",
    });
    localStorage.setItem("sub", res.sub);
    setUserId(res.sub);
  };

  return {
    userId,
  };
}
