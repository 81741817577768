// components/Cell.tsx
import React, { ReactNode } from "react";

interface CellProps {
  label: string;
  children: ReactNode;
  direction?: "horizontal" | "vertical";
  border?: boolean; // 新增的属性，用于控制下划线的显示
}

const Cell: React.FC<CellProps> = ({
  label,
  children,
  direction = "horizontal",
  border = true, // 默认值为 true，表示显示下划线
}) => {
  return (
    <div
      className={`m-h-11 gap-1.5 self-stretch ${border ? "border-b-[0.5px] border-solid border-b-[color:var(--secondary-grey-20,rgba(9,9,9,0.20))]" : ""} p-4 px-0 py-2 pr-[16px] ${direction === "horizontal" ? "flex items-center justify-between" : "block"}`}
    >
      <span className="flex-[1_0_0] text-base font-normal leading-5 tracking-[-0.3px] text-[color:var(--Black,#080808)]">
        {label}
      </span>
      <div className={`${direction === "horizontal" ? "flex items-center" : ""}`}>{children}</div>
    </div>
  );
};

export default Cell;
