import { CheckIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useBroadcastChannel } from "use-broadcast-channel";

import BottomSheet from "@/app/components/bottom-sheet";
import Icon from "@/app/components/icon";
import { StackOutputPropertiesItem } from "@/generated";
import { useGoBack } from "@/hooks/use-goback";
import useGoogleTracking from "@/hooks/use-google-tracking";
import { useJSBridge } from "@/jsbridge";
import iconTypes from "@/lib/icon-types";

import Cell from "../components/cell";
import InputText from "../components/input-text";
import NavigationBar from "../components/navigationbar";
import TextArea from "../components/textarea";

const EditProperyPage = observer(() => {
  const jsBridge = useJSBridge();
  const { goBack } = useGoBack();
  const [optionType, setOptionType] = useState<StackOutputPropertiesItem["type"]>("text");

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const { trackCustomEvent } = useGoogleTracking();
  const editProperty = useBroadcastChannel("stacks:edit-property");

  const [formData, setFormData] = useState<StackOutputPropertiesItem>({
    id: "",
    name: "",
    prompt: "",
    required: false,
    default: null,
    type: "text",
  });

  useEffect(() => {
    const propertyItem = localStorage.getItem("edit_property");
    if (propertyItem) {
      const item = JSON.parse(propertyItem);
      setFormData((predata) => {
        return {
          ...predata,
          ...item,
        };
      });
      setOptionType(item.type);
    }
  }, []);

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    editProperty(
      JSON.stringify({
        editType: formData.id ? "update" : "add",
        formData,
      }),
    );
    goBack();
  };

  const onChangeOptionType = (type: StackOutputPropertiesItem["type"]) => {
    formData.type = type;

    setFormData({ ...formData });
    setOptionType(type);
    toggleBottomSheet();
  };

  const onDeleteProperty = async () => {
    if (!jsBridge) {
      return;
    }

    const index = await jsBridge.alert({
      title: "Delete Property?",
      message: "Are you sure you want to delete this property?",
      actions: [
        {
          title: "Cancel",
          style: "cancel",
        },
        {
          title: "Delete",
          style: "destructive",
        },
      ],
    });

    if (index !== 1) {
      return;
    }
    editProperty(
      JSON.stringify({
        editType: "delete",
        formData,
      }),
    );
    trackCustomEvent("edit_property_delete_button_click", {
      property_id: formData.id,
    });
    goBack();
  };

  const toggleBottomSheet = () => {
    setIsBottomSheetOpen(!isBottomSheetOpen);
  };

  return (
    <div>
      <NavigationBar
        title="Edit Property"
        rightContent={
          <button
            type="button"
            className="disabled:opacity-40"
            onClick={onSubmit}
            disabled={formData.name.length === 0}
          >
            Done
          </button>
        }
        rightContentAsButton={false}
      />

      <div className="flex w-full flex-1 flex-col justify-start gap-4 bg-[#f2f2f2] p-4">
        <InputText
          value={formData.name}
          label="Property name"
          placeholder="Example: Cycling Speed"
          onChange={(value) => handleInputChange("name", value)}
        />
        <TextArea
          value={formData.prompt}
          label="Prompt this property"
          icon={<Icon name="Star" />}
          placeholder="(Optional) Calculate my cycling speed according to distance and time property."
          onChange={(value) => handleInputChange("prompt", value)}
        />
        <div>
          <div className="mb-2 self-stretch text-sm font-semibold leading-4 tracking-tight text-[rgba(0,0,0,0.90)]">
            Details
          </div>
          <div className="flex flex-col items-center self-stretch rounded-md bg-white py-1.5 pl-4 pr-0">
            <Cell label="Type" direction="horizontal" border={false}>
              <div
                className="flex items-center"
                onClick={() => {
                  trackCustomEvent("edit_property_type_edit_button_click", {
                    property_id: formData.id,
                  });
                  toggleBottomSheet();
                }}
              >
                <Icon name={iconTypes[formData.type].icon} />
                <p className="mx-1">{iconTypes[formData.type].name}</p>
                <Icon name="ArrowRight" />
              </div>
            </Cell>
            {/* <Cell label="Required" direction="horizontal" border={false}>
              <Switch.Root
                className="relative h-[28px] w-[50px] cursor-default rounded-full bg-[#ccc] outline-none focus:bg-[#ccc] data-[state=checked]:bg-[#34C759]"
                id="airplane-mode"
                checked={formData.required}
                onCheckedChange={onCheckedChange}
              >
                <Switch.Thumb className="block h-[24px] w-[24px] translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[24px]" />
              </Switch.Root>
            </Cell> */}
            {/* <Cell label="Default" direction="vertical" border={false}>
            <textarea
              value={formData.default}
              className="w-full focus:outline-none"
              placeholder="Untitled"
              onChange={(event) => handleInputChange("default", event.currentTarget.value)}
            />
          </Cell> */}
          </div>
        </div>
        {!!formData.id && (
          <div
            className="flex items-center gap-1 self-stretch rounded-xl bg-white px-4 py-3"
            onClick={onDeleteProperty}
          >
            <Icon name="Garbage" />
            <p className="text-base font-normal leading-5 tracking-tight text-[var(--Recording-red,#EA0303)]">
              Delete property
            </p>
          </div>
        )}
      </div>
      <BottomSheet isOpen={isBottomSheetOpen} onClose={toggleBottomSheet}>
        <div className="flex flex-col items-center">
          {Object.values(iconTypes).map((item) => {
            return (
              <div
                key={item.type}
                onClick={() => onChangeOptionType(item.type as StackOutputPropertiesItem["type"])}
                className="flex h-11 items-center justify-between self-stretch border-b-[0.5px] border-b-[color:var(--secondary-grey-20,rgba(9,9,9,0.20))] px-4 py-2 [background:#FFF]"
              >
                <div className="flex items-center gap-1.5">
                  <Icon name={item.icon as any} />
                  {item.name}
                </div>
                {optionType == item.type && <CheckIcon />}
              </div>
            );
          })}
        </div>
      </BottomSheet>
    </div>
  );
});

export default EditProperyPage;
