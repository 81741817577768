import CardList from "./components/card/card-list";
import useStackParams from "./params";

export default function StackPage() {
  const { stackId } = useStackParams();

  return (
    <div className="flex min-h-0 flex-1 flex-col py-[10px]">
      <CardList stackId={stackId} />
    </div>
  );
}
