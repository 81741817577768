import React from "react";

interface InputProps {
  value: string;
  label?: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const InputText: React.FC<InputProps> = ({ label, className = "", ...props }) => {
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    props.onChange(value);
  };

  return (
    <div className={`flex-start flex w-full flex-col ${className}`}>
      {label && (
        <div className="mb-2 self-stretch text-sm font-[590] leading-4 tracking-[-0.4px] text-[color:var(--font-gy-190,rgba(0,0,0,0.90))]">
          {label}
        </div>
      )}
      <input
        value={props.value}
        placeholder={props.placeholder}
        className="inline-flex h-10 flex-col items-center justify-center gap-1 rounded-xl bg-white px-4 py-3 text-[16px] focus:outline-none"
        type="text"
        onChange={onChangeInput}
      />
    </div>
  );
};

export default InputText;
