import { QueryErrorResetBoundary } from "@tanstack/react-query";
import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

interface ErrorBoundaryWithReloadProps {
  children: ReactNode;
}

const ErrorBoundaryWithReload: React.FC<ErrorBoundaryWithReloadProps> = ({ children }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div className="flex h-screen flex-1 items-center justify-center" role="alert">
                <div className="-mt-20 text-center">
                  <p className="text-[32px]">🚧</p>
                  <p className="mt-1.5 text-[13px]">Something went wrong! Please try again.</p>
                  <button type="button" className="mt-1.5 px-4 py-1 text-primary-orange" onClick={resetErrorBoundary}>
                    Try Again
                  </button>
                </div>
              </div>
            )}
          >
            {children}
          </ErrorBoundary>
        );
      }}
    </QueryErrorResetBoundary>
  );
};

export default ErrorBoundaryWithReload;
