import dayjs from "dayjs";

import { PropertyWithValueOutput } from "@/generated";
import { exhaustiveCheck } from "@/lib/utils";

export interface PropertyValueProps {
  property: PropertyWithValueOutput["property"];
  value: PropertyWithValueOutput["value"];
}

export default function PropertyValue({ property, value }: PropertyValueProps) {
  if (value === undefined || value === null) {
    return null;
  }

  switch (property.type) {
    case "text":
      return String(value);

    case "number":
      return String(value);

    case "boolean":
      return value ? "Yes" : "No";

    case "date-time":
      return dayjs(String(value)).format("lll");

    default: {
      exhaustiveCheck(property);
      return String(value);
    }
  }
}
