// useAnalytics.js
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";

import "@/generated";
import { analytics } from "@/lib/firebase-track";

import useUserInfo from "./use-userinfo";

export type EventName =
  | "stack_detail_page_view"
  | "return_button_click"
  | "note_edit_button_click"
  | "edit_note_button_click"
  | "delete_note_button_click"
  | "edit_stack_entry_button_click"
  | "edit_stack_button_click"
  | "delete_stack_button_click"
  | "show_more_button_click"
  | "show_less_button_click"
  | "save_button_click"
  | "stack_edit_page_view"
  | "stack_edit_complete_button_click"
  | "stack_property_delete_button_click"
  | "property_edit_entry_button_click"
  | "property_save_edit_button_click"
  | "enter_property_detail"
  | "create_property_button_click"
  | "return_from_stack_edit_button_click"
  | "edit_stack_name_count"
  | "edit_stack_prompt_count"
  | "property_sort_count"
  | "edit_property_delete_button_click"
  | "edit_property_page_view"
  | "edit_property_type_edit_button_click"
  | "complete_property_edit_button_click"
  | "edit_property_prompt_count";

const useGoogleTracking = () => {
  const { userId } = useUserInfo();

  useEffect(() => {
    if (analytics && userId) {
      logEvent(analytics, "user_id", { userId });
    }
  }, [userId]);

  const trackEvent = (eventName: string, eventParams = {}) => {
    if (analytics) {
      logEvent(analytics, eventName, {
        user_id: userId,
        page_location: location.href,
        page_path: location.hash.replace(/^#/, ""),
        ...eventParams,
      });
    }
  };

  return {
    // 页面视图
    trackPageView: (eventParams = {}) => {
      trackEvent("page_view", eventParams);
    },
    // 按钮点击事件
    trackButtonClick: (buttonName: string, additionalParams = {}) => {
      trackEvent("button_click", { buttonName, ...additionalParams });
    },
    // 其他特定事件
    trackStackEditSource: (source: string) => {
      trackEvent("stack_edit_source", { source });
    },
    // 其他自定义事件
    trackCustomEvent: (eventName: EventName, eventParams = {}) => {
      trackEvent(eventName, eventParams);
    },
  };
};

export default useGoogleTracking;
