import { StackTemplateCategory } from "@/generated";
import { cn } from "@/lib/utils";

import TemplateItem from "./template-item";

export interface TemplateGridProps {
  className?: string;
  templates: StackTemplateCategory[];
  selectedTemplates?: string[];
  onSelectedTemplatesChange?: (value: string[]) => void;
  disabled?: boolean;
}

export default function TemplateGrid({
  className,
  templates,
  selectedTemplates = [],
  onSelectedTemplatesChange,
  disabled,
}: TemplateGridProps) {
  return (
    <div>
      {templates.map((group, index) => (
        <div key={index}>
          <p className="flex items-center px-4 pb-[7px] pt-5 text-[15px] font-[590]">{group.name}</p>
          <div className={cn("grid grid-cols-[repeat(auto-fill,minmax(160px,1fr))] gap-[14px] px-4", className)}>
            {group.templates.map((template) => {
              return (
                <TemplateItem
                  key={template.id}
                  template={template}
                  checked={selectedTemplates?.includes(template.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      onSelectedTemplatesChange?.([...selectedTemplates, template.id]);
                    } else {
                      onSelectedTemplatesChange?.(selectedTemplates.filter((id) => id !== template.id));
                    }
                  }}
                  disabled={disabled}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
