import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { Ref, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";

import AppLink from "@/app/components/app-link";
import Icon from "@/app/components/icon";
import PropertyValue from "@/app/components/property-value";
import Radio from "@/app/components/radio";
import { CardWithStackResponseOutput, StackType, getListCardsFromStackQueryKey, useDeleteCard } from "@/generated";
import useGoogleTracking from "@/hooks/use-google-tracking";
import { useJSBridge } from "@/jsbridge";
import { cn } from "@/lib/utils";

export interface CardItemProps {
  card: CardWithStackResponseOutput;
  images: {
    type: "image";
    uri: string;
    presigned_url: string;
  }[];
  onMove: () => void;
  onSelect: (cardId: number, isSelect: boolean) => void;
  isChoose: boolean;
}

export default function CardItem({ card, images, onMove, isChoose, onSelect }: CardItemProps) {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { stack } = card;
  const [selected, setSelected] = useState(false);

  return (
    <section className="mx-4 flex items-center">
      <div className={isChoose ? "mr-4 w-4 transition-all" : "w-0 overflow-hidden"}>
        <Radio
          checked={selected}
          onChange={() => {
            setSelected(!selected);
            onSelect(card.id, !selected);
          }}
        />
      </div>
      <div
        className={cn("flex flex-1 flex-col rounded-3xl bg-white px-5 pb-4 transition-all", {
          "shadow-card": expanded,
        })}
      >
        <CardHeader card={card} onMove={onMove} />

        {images.length > 0 && <CardImages images={images} />}

        {stack.type === StackType.inbox ? (
          <InboxContent contentRef={ref} card={card} expanded={expanded} />
        ) : (
          <CollectionContent card={card} expanded={expanded} />
        )}

        {card.populated_properties && card.populated_properties?.length > 4 ? (
          <footer>
            <ToggleButton expanded={expanded} stackId={card.stack.id} cardId={card.id} onExpandedChange={setExpanded} />
          </footer>
        ) : null}
      </div>
    </section>
  );
}

interface CardHeaderProps {
  card: CardWithStackResponseOutput;
  onMove: () => void;
}

function CardHeader({ card, onMove }: CardHeaderProps) {
  return (
    <header className="flex h-12 items-center justify-between border-b-[0.5px] border-secondary-grey/20">
      <div className="text-sm font-semibold leading-4 text-secondary-grey/50">
        {dayjs(card.created_at).format("ll")}
      </div>
      <CardDropdownMenu card={card} onMove={onMove} />
    </header>
  );
}

interface CardImagesProps {
  images: CardItemProps["images"];
}
function CardImages({ images }: CardImagesProps) {
  return (
    <div className="hide-scrollbar mt-4 w-full flex-nowrap overflow-hidden overflow-x-auto whitespace-nowrap">
      {images.map((image, index) => {
        return (
          <div key={index} className="mr-[6px] inline-block">
            <img className="h-[72px] w-[72px] rounded-md object-cover" key={index} src={image.presigned_url} />
          </div>
        );
      })}
    </div>
  );
}

interface CardDropdownMenuProps {
  card: CardWithStackResponseOutput;
  onMove: () => void;
}

function CardDropdownMenu({ card, onMove }: CardDropdownMenuProps) {
  const queryClient = useQueryClient();
  const jsBridge = useJSBridge();
  const { trackCustomEvent } = useGoogleTracking();
  const [isTurnIntoStackAvailable, setIsTurnIntoStackAvailable] = useState(false);
  // TODO: Extract animation interruption logic to a separate hook?
  const [animating] = useState(false);
  const [open, setOpen] = useReducer((previousValue: boolean, newValue: boolean) => {
    if (animating) {
      return previousValue;
    }
    return newValue;
  }, false);

  const getTurnStackAvaliable = useCallback(async () => {
    if (jsBridge) {
      const isAvailable = await jsBridge.isTurnIntoStackAvailable();
      setIsTurnIntoStackAvailable(isAvailable);
    }
  }, [jsBridge]);

  useEffect(() => {
    getTurnStackAvaliable();
  }, [jsBridge, getTurnStackAvaliable]);

  const { isPending, mutate: performDeleteCard } = useDeleteCard({
    mutation: {
      onSuccess: () => {
        const queriesData = queryClient.getQueriesData<InfiniteData<CardWithStackResponseOutput[]>>({
          queryKey: getListCardsFromStackQueryKey({ stackId: card.stack.id }),
          exact: false,
        });

        for (const [queryKey, queryData] of queriesData) {
          if (!queryData) {
            continue;
          }

          if (!queryData.pages.some((cards) => cards.some(({ id }) => id === card.id))) {
            continue;
          }

          queryClient.setQueryData<InfiniteData<CardWithStackResponseOutput[]>>(queryKey, (oldData) => {
            if (!oldData) {
              return oldData;
            }
            const { pageParams, pages: oldPages } = oldData;
            const pages = oldPages.map((page) => page.filter(({ id }) => id !== card.id));
            return { pageParams, pages };
          });
        }
      },
    },
  });

  const moveNote = async () => {
    if (jsBridge) {
      const isSuccess = await jsBridge.changeStack(
        JSON.stringify({ from_stack_id: card.stack.id, from_card_id: card.id }),
      );
      if (isSuccess) {
        onMove();
      }
    }
  };

  const turnNote = async () => {
    if (jsBridge) {
      await jsBridge.turnIntoStack(JSON.stringify({ stack_id: card.stack.id, card_ids: [card.id] }));
    }
  };

  const deleteNote = async () => {
    if (!jsBridge) {
      return;
    }

    const index = await jsBridge.alert({
      title: "Delete Note?",
      message: "Are you sure you want to delete this note?",
      actions: [
        {
          title: "Cancel",
          style: "cancel",
        },
        {
          title: "Delete",
          style: "destructive",
        },
      ],
    });

    if (index !== 1) {
      return;
    }

    performDeleteCard({ pathParams: { stackId: card.stack.id, cardId: card.id }, data: {} });
    trackCustomEvent("delete_note_button_click", {
      stack_id: card.stack.id,
      note_id: card.id,
    });
  };

  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        trackCustomEvent("note_edit_button_click", {
          stack_id: card.stack.id,
          note_id: card.id,
        });
      }}
    >
      <DropdownMenu.Trigger asChild disabled={isPending}>
        <button
          type="button"
          className={cn("mx-[-16px] box-content flex h-full w-4 items-center justify-center px-[16px] outline-none", {
            "opacity-50": isPending,
          })}
        >
          <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.0938 2.96094C13.9141 2.96094 14.5781 2.30469 14.5781 1.48438C14.5781 0.664062 13.9141 0 13.0938 0C12.2734 0 11.6094 0.664062 11.6094 1.48438C11.6094 2.30469 12.2734 2.96094 13.0938 2.96094Z"
              fill="black"
              fillOpacity="0.85"
            />
            <path
              d="M7.28906 2.96094C8.10938 2.96094 8.76562 2.30469 8.76562 1.48438C8.76562 0.664062 8.10938 0 7.28906 0C6.46875 0 5.80469 0.664062 5.80469 1.48438C5.80469 2.30469 6.46875 2.96094 7.28906 2.96094Z"
              fill="black"
              fillOpacity="0.85"
            />
            <path
              d="M1.48438 2.96094C2.30469 2.96094 2.96094 2.30469 2.96094 1.48438C2.96094 0.664062 2.30469 0 1.48438 0C0.664062 0 0 0.664062 0 1.48438C0 2.30469 0.664062 2.96094 1.48438 2.96094Z"
              fill="black"
              fillOpacity="0.85"
            />
          </svg>
        </button>
      </DropdownMenu.Trigger>
      <AnimatePresence>
        {open ? (
          <DropdownMenu.Portal forceMount>
            <DropdownMenu.Content align="end" asChild>
              <motion.div
                className="min-w-[250px] origin-top-right rounded-xl bg-white shadow-dropdown-menu"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.5, type: "spring" }}
              >
                <DropdownMenu.Item className="cursor-pointer">
                  <AppLink
                    onClick={() => {
                      trackCustomEvent("edit_note_button_click", {
                        stack_id: card.stack.id,
                        note_id: card.id,
                      });
                    }}
                    className="flex h-[43px] items-center justify-between whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-3"
                    to={`/stacks/${card.stack.id}/cards/${card.id}/edit`}
                    customAppSchemeURL={`stackie://stacks/${card.stack.id}/cards/${card.id}/edit`}
                  >
                    <span className="text-[16px]">Edit Note</span>
                    <Icon width={14} height={14} name="Pen" className="mr-[3px]" />
                  </AppLink>
                </DropdownMenu.Item>
                {card.stack.type === StackType.inbox && (
                  <>
                    <DropdownMenu.Item
                      className="flex h-[43px] items-center justify-between whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-3"
                      onClick={moveNote}
                    >
                      <span className="text-[16px]">Move Note</span>
                      <Icon width={19} height={19} name="Move" />
                    </DropdownMenu.Item>
                    {isTurnIntoStackAvailable && (
                      <DropdownMenu.Item
                        className="flex h-[43px] items-center justify-between whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-3"
                        onClick={turnNote}
                      >
                        <span className="text-[16px]">Turn Into Stack</span>
                        <Icon width={19} height={19} name="Stack" />
                      </DropdownMenu.Item>
                    )}
                  </>
                )}
                <DropdownMenu.Item
                  className="flex h-[43px] items-center justify-between whitespace-nowrap px-3"
                  onClick={deleteNote}
                >
                  <span className="text-[16px]">Delete Note</span>
                  <Icon width={19} height={19} name="GarbageBlack" />
                </DropdownMenu.Item>
              </motion.div>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        ) : null}
      </AnimatePresence>
    </DropdownMenu.Root>
  );
}

interface CardContentProps {
  contentRef?: Ref<HTMLDivElement>;
  card: CardWithStackResponseOutput;
  expanded: boolean;
}

function InboxContent({ contentRef, card }: CardContentProps) {
  const populatedProperties = card.populated_properties;

  return (
    <main className="group">
      <div className="py-3 text-base text-black transition-all">
        <div ref={contentRef} className="whitespace-pre-wrap break-words">
          {populatedProperties.map(({ property, value }) => (
            <PropertyValue key={property.id} property={property} value={value} />
          ))}
        </div>
      </div>

      <div className="col-span-2 h-0 border-b-[0.5px] border-secondary-grey/20 group-last:hidden" />
    </main>
  );
}

function CollectionContent({ card, expanded }: CardContentProps) {
  return (
    <main className={cn("mt-3 grid grid-cols-[minmax(0,3fr)_7fr] gap-x-2 gap-y-[16px]")}>
      {card.populated_properties
        ?.filter((item, index) => item.value && ((index > 3 && expanded) || index <= 3))
        .map(({ property, value }) => (
          <Fragment key={property.id}>
            <div className="text-sm font-semibold leading-5 text-secondary-grey/50">{property.name}</div>
            <div className={cn("whitespace-pre-wrap break-all text-base leading-5 text-black", {})}>
              <PropertyValue property={property} value={value} />
            </div>
          </Fragment>
        ))}
    </main>
  );
}

interface ToggleButtonProps {
  expanded: boolean;
  cardId: number;
  stackId: number;
  onExpandedChange?: (value: boolean) => void;
}

function ToggleButton({ expanded, cardId, stackId, onExpandedChange }: ToggleButtonProps) {
  const { trackCustomEvent } = useGoogleTracking();
  const onToggleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    trackCustomEvent(expanded ? "show_less_button_click" : "show_more_button_click", {
      stack_id: stackId,
      note_id: cardId,
    });
    onExpandedChange?.(!expanded);
  };

  return (
    <button type="button" className="mt-3 text-[13px] leading-[18px] text-primary-orange" onClick={onToggleClick}>
      {expanded ? "Show Less" : "Show More"}
    </button>
  );
}
