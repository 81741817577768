import { useQueryClient } from "@tanstack/react-query";
import { Suspense, useMemo } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";

import ThemeColorProvider from "@/app/(home)/components/theme-provider";
import ErrorBoundaryWithReload from "@/app/components/error-boundary-with-reload";
import {
  StackOutput,
  StackWithCardResponse,
  getListStacksQueryKey,
  listStacks,
  useGetStackSuspense,
} from "@/generated";
import { useJSBridge } from "@/jsbridge";

import Dropdown from "./components/dropdown/dropdown";
import useStackParams from "./params";

export default function StackLayout() {
  const { stackId } = useStackParams();
  const [searchParams] = useSearchParams();

  const navigationBarHidden = useMemo(() => searchParams.get("navigationBarHidden") === "true", [searchParams]);

  const queryClient = useQueryClient();

  const cachedStacks = queryClient.getQueryData<Awaited<ReturnType<typeof listStacks>>>(getListStacksQueryKey());
  const cachedStacksState = queryClient.getQueryState<Awaited<ReturnType<typeof listStacks>>>(getListStacksQueryKey());
  let cachedStack: StackWithCardResponse | undefined;
  try {
    cachedStack = cachedStacks?.find((stack) => stack.id === stackId);
  } catch {
    // ignore
  }

  const { data: stack } = useGetStackSuspense(
    { stackId },
    { query: { initialData: cachedStack, initialDataUpdatedAt: cachedStacksState?.dataUpdatedAt } },
  );

  const cssProperties = useMemo(() => {
    const value: Record<string, unknown> = {
      "--theme-color": `linear-gradient(45deg, ${stack.theme_color_palette.light} 10%, #ffffff)`,
    };

    if (navigationBarHidden) {
      value["--sub-navigation-bar-height"] = "0";
    }

    return value as React.CSSProperties;
  }, [stack.theme_color_palette.light, navigationBarHidden]);

  return (
    <ThemeColorProvider themeColor={stack.theme_color_palette.light}>
      <div className="min-h-[100svh] bg-[var(--theme-color)]" style={cssProperties}>
        {navigationBarHidden ? null : <NavigationBar stack={stack} />}

        <ContentSafeArea>
          <Suspense fallback={null}>
            <ErrorBoundaryWithReload>
              <Outlet />
            </ErrorBoundaryWithReload>
          </Suspense>
        </ContentSafeArea>
      </div>
    </ThemeColorProvider>
  );
}

interface NavigationBarProps {
  stack: StackOutput;
}
function NavigationBar({ stack }: NavigationBarProps) {
  const jsBridge = useJSBridge();
  const params = useParams();

  const closePage = () => {
    jsBridge?.closePage();
  };

  const showDropdown = useMemo(() => {
    if (params.cardId) {
      return false;
    }

    if (stack.type === "inbox") {
      return false;
    }

    return true;
  }, [params.cardId, stack.type]);

  return (
    <div className="fixed inset-x-0 top-0 z-10 bg-[var(--theme-color)]">
      <div className="mt-[env(safe-area-inset-top)] flex h-[var(--sub-navigation-bar-height)] items-center justify-between px-4">
        <div className="flex h-full w-10">
          <button type="button" className="m-[-8px] p-[8px]" onClick={closePage}>
            <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.45492 1.75199L1.2071 8.99985L8.45492 16.2477C8.47932 16.2721 8.50072 16.2984 8.51902 16.3262C8.64712 16.5203 8.62582 16.784 8.45492 16.9548C8.25972 17.1501 7.94312 17.1501 7.74782 16.9548L0.14644 9.35335C0.05267 9.25965 0 9.13245 0 8.99985C0 8.86725 0.05268 8.74005 0.14644 8.64625L7.74782 1.04488C7.94312 0.849623 8.25972 0.849623 8.45492 1.04488C8.47932 1.06929 8.50072 1.09559 8.51902 1.12331C8.64712 1.31739 8.62582 1.58113 8.45492 1.75199Z"
                fill="black"
              />
            </svg>
          </button>
        </div>

        <h1 className="min-w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-xl font-bold">
          {stack.emoji} {stack.name}
        </h1>

        <div className="flex h-full w-10 items-center justify-end">{showDropdown ? <Dropdown /> : null}</div>
      </div>
    </div>
  );
}

function ContentSafeArea({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex min-h-[100svh] flex-col pb-[env(safe-area-inset-bottom)] pl-[env(safe-area-inset-left)] pr-[env(safe-area-inset-right)] pt-[env(safe-area-inset-top)]">
      <div className="flex min-h-0 flex-1 flex-col pt-[var(--sub-navigation-bar-height)]">{children}</div>
    </div>
  );
}
