import { CSSProperties, Fragment } from "react";

import AppLink from "@/app/components/app-link";
import { StackOutput, StackType, StackWithCardResponse, StackWithCardResponseCard } from "@/generated";

import CardPreview from "../card/card-preview";

export interface StackItemProps {
  stack: StackWithCardResponse;
}

export function StackItem({ stack }: StackItemProps) {
  return (
    <AppLink to={`/stacks/${stack.id}`}>
      <section
        className="flex h-[200px] flex-col justify-between rounded-xl bg-[var(--stack-theme-color)]"
        style={
          {
            "--stack-theme-color": stack.theme_color_palette.light,
          } as CSSProperties
        }
      >
        <StackItemHeader stack={stack} />
        <StackItemContent stack={stack} card={stack.card} totalCards={stack.total_cards ?? 0} />
      </section>
    </AppLink>
  );
}

export interface StackItemHeaderProps {
  stack: StackWithCardResponse;
}

export function StackItemHeader({ stack }: StackItemHeaderProps) {
  return (
    <header className="mx-[12px] my-[14px] flex font-semibold">
      <div className="leading-5">{stack.emoji}</div>
      <div className="ms-1 line-clamp-2 overflow-hidden text-ellipsis break-words leading-5">{stack.name}</div>
    </header>
  );
}

export interface StackItemContentProps {
  stack: StackOutput;
  card?: StackWithCardResponseCard;
  totalCards: number;
}

export function StackItemContent({ stack, card }: StackItemContentProps) {
  if (!card) {
    return <CardPlaceholder stack={stack} />;
  }

  return <main>{card ? <CardPreview card={card} /> : null}</main>;
}

interface CardPlaceholderProps {
  stack: StackOutput;
}

function CardPlaceholder({ stack }: CardPlaceholderProps) {
  switch (stack.type) {
    case StackType.inbox:
      return <InboxCardPlaceholder />;
    case StackType.collection:
      return <CollectionCardPlaceholder stack={stack} />;
    default: {
      const _exhaustiveCheck: never = stack.type;
      _exhaustiveCheck;
      return null;
    }
  }
}

function InboxCardPlaceholder() {
  return (
    <div className="mx-[3px] my-[2px]">
      <div className="mx-[7px] my-[10px] h-[111px] rotate-[8deg] rounded-xl bg-white/40 shadow-card-placeholder" />
    </div>
  );
}

interface CollectionCardPlaceholderProps {
  stack: StackOutput;
}

function CollectionCardPlaceholder({ stack }: CollectionCardPlaceholderProps) {
  const { properties } = stack;
  return (
    <section className="m-2 h-[116px] rounded-xl bg-white/40 shadow-card-placeholder">
      <main className="mt-[19px] grid grid-cols-[minmax(0,2fr)_3fr] gap-x-1 gap-y-[2px] px-2">
        {properties.slice(0, 4).map((property) => (
          <Fragment key={property.id}>
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[10px] font-semibold text-secondary-grey/20">
              {property.name}
            </div>
            <div className="my-[2px] h-[14px] rounded-[4px] bg-secondary-grey/[0.04]" />
          </Fragment>
        ))}
      </main>
    </section>
  );
}
