import React from "react";

interface RadioProps {
  checked: boolean;
  onChange: () => void;
}

const Radio: React.FC<RadioProps> = ({ checked, onChange }) => {
  return (
    <div
      className={`flex h-4 w-4 cursor-pointer items-center justify-center rounded-full transition-colors duration-300 ${
        checked ? "bg-black" : "bg-[#c3c3c3]"
      }`}
      onClick={onChange}
    >
      {checked && (
        <svg
          className="h-4 w-4 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
      )}
    </div>
  );
};

export default Radio;
