import { logEvent } from "firebase/analytics";
import posthog from "posthog-js";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { analytics } from "@/lib/firebase-track";

export default function TrackingLayout() {
  const location = useLocation();

  useEffect(() => {
    if (import.meta.env.PROD) {
      // posthog
      const properties = {
        $current_url: window.location.href,
        $pathname: location.pathname,
      };
      posthog.capture("$pageview", { ...properties });
      // Hash Router 下 posthog 自己拿到的 path 全是 /
      // 使用 register 手动提供这个变量
      posthog.register({ ...properties });
      // google analytics
      logEvent(analytics, "page_view", {
        page_location: properties.$current_url,
        page_path: properties.$pathname,
      });
    }
  }, [location]);
  return <Outlet />;
}
