import React from "react";

interface TextAreaProps {
  value: string;
  label?: string;
  className?: string;
  icon?: React.ReactNode;
  height?: number;
  maxLength?: number;
  placeholder?: string;
  onChange: (value: string) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  label,
  icon,
  maxLength = 300,
  height = 52,
  className,
  placeholder,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    props.onChange(value);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <div className="mb-2 flex items-center self-stretch text-sm font-[590] leading-4 tracking-[-0.4px] text-[color:var(--font-gy-190,rgba(0,0,0,0.90))]">
          {icon && <span className="mr-2">{icon}</span>}
          {label}
        </div>
      )}

      <div className="inline-flex flex-col items-center justify-center gap-1 rounded-xl bg-white px-4 py-3">
        <textarea
          style={{ minHeight: height }}
          className="flex shrink grow basis-0 flex-col items-start justify-start gap-2 self-stretch text-[16px] focus:outline-none"
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxLength}
        />
        <div className="font-['PingFang SC'] h-5 w-full text-right text-xs font-normal leading-tight text-black/25">
          {value.length}/{maxLength}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
