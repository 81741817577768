export default function LoadingIndicator() {
  return (
    <svg viewBox="0 0 52 12" className="h-10 w-10 text-secondary-grey/60">
      <circle fill="currentColor" stroke="none" cx="6" cy="6" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>
      </circle>
      <circle fill="currentColor" stroke="none" cx="26" cy="6" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>
      </circle>
      <circle fill="currentColor" stroke="none" cx="46" cy="6" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>
      </circle>
    </svg>
  );
}
