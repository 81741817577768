import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useBroadcastChannel } from "use-broadcast-channel";

import Icon from "@/app/components/icon";
import { EditStackRequestPropertiesAnyOfItem, useEditStack, useGetStackSuspense } from "@/generated";
import { useGoBack } from "@/hooks/use-goback";
import useGoogleTracking from "@/hooks/use-google-tracking";
import useLoading from "@/hooks/use-loading";
import useToast from "@/hooks/use-toast";
import { generateUniqueId } from "@/lib/utils";

import DraggableList from "../components/draggable-list";
import InputText from "../components/input-text";
import NavigationBar from "../components/navigationbar";
import TextArea from "../components/textarea";
import { useStack } from "./useStack";

const EditStackPage: React.FC = observer(() => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stackId = queryParams.get("stackId");
  const { goBack } = useGoBack();
  const { showToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { stack, addProperty, removeProperty, updateProperty, updateStack } = useStack();
  const { trackCustomEvent } = useGoogleTracking();
  useBroadcastChannel("stacks:edit-property", (event) => {
    const data = JSON.parse(event.data);
    if (data.editType === "add") {
      data.formData.id = generateUniqueId();
      addProperty(data.formData);
    } else if (data.editType === "delete") {
      removeProperty(data.formData.id);
    } else if (data.editType === "update") {
      updateProperty(data.formData.id, data.formData);
    }
    if (["add", "update"].includes(data.editType)) {
      trackCustomEvent("complete_property_edit_button_click", {
        property_id: data.formData.id,
        stack_id: stackId,
      });
    }
  });

  const { data: stackInfo } = useGetStackSuspense(
    { stackId: Number(stackId) },
    {
      query: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  );

  const { mutateAsync: performEditStack } = useEditStack({
    mutation: {
      onSuccess: () => {
        hideLoading();
        goBack();
        trackCustomEvent("stack_edit_complete_button_click", {
          stack_id: stackId,
        });
      },
      onError: (err: any) => {
        showToast(err.response?.data.message);
        hideLoading();
      },
    },
  });

  useEffect(() => {
    if (stackInfo) {
      updateStack({
        id: stackInfo.id,
        name: stackInfo.name,
        prompt: stackInfo.prompt,
        updated_at: stackInfo.updated_at,
        properties: stackInfo.properties,
      });
    }
  }, [stackInfo, updateStack]);

  const handleChange = (name: string, value: any) => {
    updateStack({
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (!stack.properties.length) {
      return showToast("at least one property is required");
    }
    showLoading();
    performEditStack({
      pathParams: {
        stackId: Number(stackId),
      },
      data: {
        name: stack.name,
        prompt: stack.prompt,
        properties: stack.properties.map((v) => {
          const result: EditStackRequestPropertiesAnyOfItem = v;
          if (!result.id || result.id.length <= 18) {
            delete result.id;
          }
          return result;
        }),
      },
    });
  };

  return (
    <div>
      <NavigationBar title="Edit Stack" rightContent={<span onClick={handleSubmit}>Done</span>} />
      <div className="flex w-full flex-1 flex-col justify-start gap-4 bg-[#f2f2f2] p-4">
        <InputText
          value={stack.name}
          label="Stack name"
          placeholder="Untitled"
          onChange={(value) => handleChange("name", value)}
        />
        <TextArea
          value={stack.prompt}
          label="Prompt your stack"
          icon={<Icon name="Star" />}
          placeholder={
            "(Optional) This helps Stackie understand what to categorize into this stack.\nExample: Log my cycling and swimming record."
          }
          onChange={(value) => handleChange("prompt", value)}
        />
        <DraggableList
          label="Properties"
          stackId={stack.id}
          list={stack.properties}
          onChange={(value) => handleChange("properties", value)}
          onDelete={(id) => removeProperty(id)}
        />
      </div>
    </div>
  );
});

export default EditStackPage;
