import React from "react";
import { Fragment } from "react/jsx-runtime";

import { PropertyWithValueOutput, StackOutput, StackType } from "@/generated";

import PropertyValue from "./property-value";

export interface StackPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  stack: StackOutput;
  example?: Record<string, PropertyWithValueOutput["value"]>;
}

export default function StackPreview({ stack, example, ...props }: StackPreviewProps) {
  if (stack.type === StackType.inbox) {
    return (
      <div className="flex min-h-0 flex-1 flex-col items-center justify-center text-xl leading-6 text-secondary-grey/20">
        <p>Take it easy.</p>
        <p>No rush to organize.</p>
      </div>
    );
  }

  return (
    <div {...props}>
      <div className="mx-1 break-words text-[13px] leading-[18px] text-black empty:hidden">{stack.prompt}</div>

      <section className="mt-4 flex min-h-0 flex-1 flex-col rounded-2xl bg-[rgba(255,255,255,0.60)] px-4 py-2">
        <main className="grid min-h-0 flex-1 grid-cols-[minmax(0,2fr)_3fr] gap-x-3">
          {stack.properties.map((property) => (
            <Fragment key={property.id}>
              <div className="break-words py-2 text-sm font-semibold leading-4 text-secondary-grey/70">
                {property.name}
              </div>
              <div className="py-[10px] leading-5 text-secondary-grey/20">
                <div className="line-clamp-4 break-words">
                  {example?.[property.name] ? (
                    <PropertyValue property={property} value={example[property.name]} />
                  ) : (
                    property.type
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </main>
      </section>
    </div>
  );
}
