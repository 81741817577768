import dayjs from "dayjs";
import { Fragment } from "react";

import PropertyValue from "@/app/components/property-value";
import { CardWithStackResponseOutput, StackType } from "@/generated";

export interface CardPreviewProps {
  card: CardWithStackResponseOutput;
}

export default function CardPreview({ card }: CardPreviewProps) {
  const { stack } = card;

  const populatedProperties = card.populated_properties;

  return (
    <section className="flex h-[126px] flex-col gap-2 rounded-xl bg-white p-[12px] pt-[16px] shadow-card-preview contain-strict">
      <header className="text-[10px] font-semibold text-secondary-grey/20">
        {dayjs(card.created_at).format("ll")}
      </header>
      {stack.type === StackType.inbox ? (
        <main className="line-clamp-4 break-words text-[13px] text-black">{String(populatedProperties[0].value)}</main>
      ) : (
        <main className="grid grid-cols-[minmax(0,2fr)_3fr] items-center gap-x-1">
          {populatedProperties
            .filter(({ value }) => value !== undefined && value !== null)
            .slice(0, 4)
            .map(({ property, value }) => (
              <Fragment key={property.id}>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[10px] font-semibold text-secondary-grey/50">
                  {property.name}
                </div>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[13px] text-black">
                  <PropertyValue property={property} value={value} />
                </div>
              </Fragment>
            ))}
        </main>
      )}
    </section>
  );
}
