import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isOpen, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 10); // Slight delay to allow rendering before animation
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => setShouldRender(false), 300); // 300ms matches the transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-end justify-center">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div
        className={`mx-auto w-full max-w-md transform rounded-t-lg bg-white pb-[env(safe-area-inset-top)] shadow-lg transition-transform duration-300 ${
          isVisible ? "translate-y-0" : "translate-y-full"
        }`}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default BottomSheet;
