import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

interface NavigationBarProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  header?: React.ReactNode;
  headerExtra?: React.ReactNode;
  bordered?: boolean;
  className?: string;
}

export default function SubpageLayout({ children, ...rest }: PropsWithChildren<Omit<NavigationBarProps, "bordered">>) {
  const [bordered, setBordered] = useState(false);
  const dummyRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (dummyRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          setBordered(entries.length ? entries[0].intersectionRatio === 0 : false);
        },
        { root: null, threshold: [0] },
      );
      observer.observe(dummyRef.current);
      return () => observer.disconnect();
    }
  }, []);

  return (
    <div className="min-h-[100svh]">
      <NavigationBar {...rest} bordered={bordered} />
      <div ref={dummyRef} className="h-[1px] w-full opacity-0"></div>
      <ContentSafeArea>{children}</ContentSafeArea>
    </div>
  );
}

function NavigationBar({
  bordered,
  left,
  right,
  header,
  headerExtra,
  className,
}: PropsWithChildren<NavigationBarProps>) {
  return (
    <div
      className={cn(
        "fixed inset-x-0 top-0 z-10 border-b backdrop-blur transition-all",
        className,
        bordered ? "border-slate-200 bg-opacity-90" : "border-transparent bg-opacity-0",
      )}
    >
      <div className="mt-[env(safe-area-inset-top)] flex h-[var(--sub-navigation-bar-height)] items-center justify-between px-4">
        <div className="flex h-full w-10 flex-shrink-0">{left}</div>
        {header}
        <div className="flex h-full w-10 flex-shrink-0">{right}</div>
      </div>
      {headerExtra}
    </div>
  );
}

function ContentSafeArea({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex min-h-[100svh] flex-col pb-[env(safe-area-inset-bottom)] pl-[env(safe-area-inset-left)] pr-[env(safe-area-inset-right)] pt-[env(safe-area-inset-top)]">
      <div className="flex min-h-0 flex-1 flex-col pt-[var(--navigation-bar-height)]">{children}</div>
    </div>
  );
}
