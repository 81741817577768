import { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface StackParams {
  cardId: string;
}

export default function useCardParams() {
  const { cardId: cardIdString } = useParams();

  const cardId = useMemo(() => {
    if (!cardIdString) {
      return null;
    }

    const cardId = parseInt(cardIdString, 10);
    if (isNaN(cardId)) {
      return null;
    }

    return cardId;
  }, [cardIdString]);

  if (!cardId) {
    throw new Error("Card not found");
  }

  return { cardId };
}
