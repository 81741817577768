import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useFormState } from "react-hook-form";

import Icon from "@/app/components/icon";
import { useCardImages } from "@/app/stacks/[stackId]/params";
import {
  CardWithStackResponseOutput,
  EditCardRequestProperties,
  StackType,
  getGetCardQueryKey,
  getListCardsFromStackQueryKey,
  useEditCard,
  useGetCardSuspense,
} from "@/generated";
import useGoogleTracking from "@/hooks/use-google-tracking";
import { useJSBridge } from "@/jsbridge";
import iconTypes from "@/lib/icon-types";
import { cn } from "@/lib/utils";

import DateTimePicker from "./date-time-picker";
import TextEditor from "./text-editor";

export interface CardEditorProps {
  stackId: number;
  cardId: number;
}

interface ImageParams {
  type: "image";
  uri: string;
  presigned_url: string;
}

type PropertyType = keyof typeof iconTypes;

export default function CardEditor({ stackId, cardId }: CardEditorProps) {
  const { data: card } = useGetCardSuspense({ stackId, cardId });
  const newCard = useMemo(() => {
    return [card];
  }, [card]);
  const { cardImages, setCardImages, getRealImageUrl } = useCardImages(newCard);
  const { trackCustomEvent } = useGoogleTracking();
  const queryClient = useQueryClient();
  const { mutateAsync: performEditCard } = useEditCard({
    mutation: {
      onSuccess: (updatedCard) => {
        queryClient.setQueryData(getGetCardQueryKey({ stackId, cardId }), updatedCard);

        const queriesData = queryClient.getQueriesData<InfiniteData<CardWithStackResponseOutput[]>>({
          queryKey: getListCardsFromStackQueryKey({ stackId: card.stack.id }),
          exact: false,
        });

        for (const [queryKey, queryData] of queriesData) {
          if (!queryData) {
            continue;
          }

          if (!queryData.pages.some((cards) => cards.some((card) => card.id === updatedCard.id))) {
            continue;
          }

          queryClient.setQueryData<InfiniteData<CardWithStackResponseOutput[]>>(queryKey, (oldData) => {
            if (!oldData) {
              return oldData;
            }
            const { pageParams, pages: oldPages } = oldData;
            const pages = oldPages.map((page) => page.map((card) => (card.id === updatedCard.id ? updatedCard : card)));
            return { pageParams, pages };
          });
        }
        setShowSave(false);
        trackCustomEvent("save_button_click", {
          note_id: card.id,
          stack_id: card.stack.id,
        });
      },
    },
  });

  const form = useForm<CardWithStackResponseOutput>({ defaultValues: card });
  const [showSave, setShowSave] = useState(false);
  useEffect(() => {
    if (form.getValues("updated_at") !== card.updated_at) {
      form.reset(card);
    }
  }, [form, card]);

  const onCloseImage = (id: number, index: number) => {
    const newImages = JSON.parse(JSON.stringify(cardImages));
    newImages[id].splice(index, 1);
    setCardImages(newImages);
    setShowSave(true);
  };

  const onUploadImage = async (urls: string[]) => {
    const newImages = JSON.parse(JSON.stringify(cardImages));
    // 先显示占位图
    for (let index = 0; index < urls.length; index++) {
      const element = urls[index];
      newImages[card.id].push({
        type: "image",
        uri: element,
      });
    }
    setCardImages(newImages);
    // 加载远程图片去显示
    const presignedImagesPromises: Promise<ImageParams>[] = newImages[card.id].map(async (item: ImageParams) => {
      if (item.uri && !item.presigned_url) {
        const res = await getRealImageUrl(item.uri);
        item.presigned_url = res.presigned_url;
      }
      return item;
    });
    newImages[card.id] = await Promise.all(presignedImagesPromises);
    setShowSave(true);
    setCardImages(JSON.parse(JSON.stringify(newImages)));
  };

  const onSubmit = async (card: CardWithStackResponseOutput) => {
    const properties: EditCardRequestProperties = {};

    for (const {
      property: { name },
      value,
    } of card.populated_properties ?? []) {
      properties[name] = value;
    }

    return performEditCard({
      pathParams: { stackId, cardId },
      data: {
        ...card,
        properties,
        attachments: cardImages[card.id].map((v) => {
          return {
            type: v.type,
            uri: v.uri,
          };
        }),
      },
    });
  };

  if (form.formState.isLoading) {
    return <div className="p-2">Loading...</div>;
  }

  return (
    <FormProvider {...form}>
      <form
        className={cn("mx-4 flex flex-col rounded-3xl bg-white px-5 pb-4 shadow-card")}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CardHeader card={card} showSave={showSave} />
        <CardImages
          images={cardImages[card.id] || []}
          onClose={(index) => onCloseImage(card.id, index)}
          onUpload={onUploadImage}
        />
        <ContentEditor card={card} />
      </form>
    </FormProvider>
  );
}

interface CardHeaderProps {
  card: CardWithStackResponseOutput;
  showSave: boolean;
}

function CardHeader({ card, showSave }: CardHeaderProps) {
  const { isSubmitting, isDirty } = useFormState();
  return (
    <header className="flex h-12 items-center justify-between border-b-[0.5px] border-secondary-grey/20 text-sm font-semibold text-secondary-grey/50">
      <div>{dayjs(card.created_at).format("ll")}</div>
      <button
        className="mx-[-16px] h-full px-[16px] text-primary-orange disabled:text-secondary-grey/30"
        disabled={isSubmitting || (!isDirty && !showSave)}
      >
        {isSubmitting ? "Saving" : "Save"}
      </button>
    </header>
  );
}

interface CardImagesProps {
  images: {
    type: string;
    uri: string;
    presigned_url: string;
  }[];
  onClose: (index: number) => void;
  onUpload: (urls: string[]) => void;
}
function CardImages({ images, onClose, onUpload }: CardImagesProps) {
  const jsBridge = useJSBridge();
  const MAX_IMAGE_COUNT = 5;
  const [isPickImageAvailable, setisPickImageAvailable] = useState(false);
  const [direction, setDirection] = useState("left");
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const [animating, setAnimating] = useState(false);
  const [open, setOpen] = useReducer((previousValue: boolean, newValue: boolean) => {
    if (animating) {
      return previousValue;
    }
    return newValue;
  }, false);

  const getImageAvailable = useCallback(async () => {
    if (jsBridge) {
      const isAvailable = await jsBridge.isPickImageAvailable();
      setisPickImageAvailable(isAvailable);
    }
  }, [jsBridge]);

  useEffect(() => {
    getImageAvailable();
  }, [jsBridge, getImageAvailable]);

  useEffect(() => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      if (rect.left < windowWidth / 2) {
        setDirection("left");
      } else {
        setDirection("right");
      }
    }
  }, [open]);

  return (
    <div className="hide-scrollbar mt-4 flex w-full flex-nowrap overflow-x-auto whitespace-nowrap">
      {images.map((image, index) => {
        return (
          <div key={index} className="relative mr-[6px] flex-none">
            {image.presigned_url ? (
              <img className="h-[72px] w-[72px] rounded-md object-cover" key={index} src={image.presigned_url} />
            ) : (
              <div className="inline-flex h-[72px] w-[72px] shrink-0 items-center justify-center rounded-md [background:#F2F2F2]"></div>
            )}
            <div className="absolute right-1 top-1 pb-1 pl-1" onClick={() => onClose(index)}>
              <Icon name="Close" />
            </div>
          </div>
        );
      })}
      {isPickImageAvailable && (
        <DropdownMenu.Root
          open={open}
          onOpenChange={(isOpen) => {
            if (images.length >= MAX_IMAGE_COUNT) {
              return jsBridge?.toast("Upload up to 5 pictures");
            }
            setOpen(isOpen);
          }}
        >
          <DropdownMenu.Trigger asChild>
            <div
              ref={triggerRef}
              className="inline-flex h-[72px] w-[72px] shrink-0 items-center justify-center rounded-md [background:#F2F2F2]"
            >
              <Icon name="Plus" />
            </div>
          </DropdownMenu.Trigger>
          <AnimatePresence>
            {open ? (
              <DropdownMenu.Portal forceMount>
                <DropdownMenu.Content align="start" asChild>
                  <motion.div
                    className="min-w-[250px] origin-top-right rounded-xl bg-white shadow-dropdown-menu"
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                      x: direction === "left" ? "-50%" : "50%",
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      x: "0%",
                    }}
                    exit={{
                      opacity: 0,
                      scale: 0.5,
                      x: direction === "left" ? "-50%" : "50%",
                    }}
                    transition={{ duration: 0.5, type: "spring" }}
                    onAnimationStart={() => setAnimating(true)}
                    onAnimationComplete={() => setAnimating(false)}
                  >
                    <DropdownMenu.Item className="cursor-pointer">
                      <div
                        onClick={async () => {
                          if (jsBridge) {
                            const result: string[] = await jsBridge.pickImage(
                              JSON.stringify({ from: "camera", selectionLimit: 1 }),
                            );
                            onUpload(result);
                          }
                        }}
                        className="flex h-[43px] items-center justify-between whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-3"
                      >
                        <span className="text-[16px]">Camera</span>
                        <Icon width={20} height={20} name="Camera" className="mr-[3px]" />
                      </div>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                      onClick={async () => {
                        if (jsBridge) {
                          const remainCount = MAX_IMAGE_COUNT - images.length > 1 ? MAX_IMAGE_COUNT - images.length : 1;
                          const result: string[] = await jsBridge.pickImage(
                            JSON.stringify({ from: "photo_library", selectionLimit: remainCount }),
                          );
                          onUpload(result);
                        }
                      }}
                      className="flex h-[43px] items-center justify-between whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-3"
                    >
                      <span className="text-[16px]">Photo Library</span>
                      <Icon width={20} height={20} name="Image" />
                    </DropdownMenu.Item>
                  </motion.div>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            ) : null}
          </AnimatePresence>
        </DropdownMenu.Root>
      )}
    </div>
  );
}

interface ContentEditorProps {
  card: CardWithStackResponseOutput;
}

function ContentEditor({ card }: ContentEditorProps) {
  switch (card.stack.type) {
    case StackType.inbox:
      return <InboxContentEditor />;
    case StackType.collection:
      return <CollectionContentEditor />;
    default: {
      const _exhaustiveCheck: never = card.stack.type;
      _exhaustiveCheck;
      return null;
    }
  }
}

function InboxContentEditor() {
  const form = useFormContext();

  const { fields } = useFieldArray({
    control: form.control,
    name: "populated_properties",
  });

  return (
    <div className="flex flex-col gap-y-2 py-3 text-base text-black">
      {fields.map((field, index) => (
        <div key={index}>
          <textarea
            key={field.id}
            className="h-80 w-full resize-none rounded-md border border-[rgba(9,9,9,0.10)] px-[6px] py-[8px] focus:outline-none"
            {...form.register(`populated_properties.${index}.value`)}
          />
        </div>
      ))}
    </div>
  );
}

function CollectionContentEditor() {
  const form = useFormContext();

  const { fields } = useFieldArray({
    control: form.control,
    name: "populated_properties",
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div
          className={
            form.getValues(`populated_properties.${index}.property.type`) == "boolean"
              ? "mt-[8px] flex items-center justify-between"
              : "mt-[8px] flex-col"
          }
          key={field.id}
        >
          <div className="flex items-center overflow-hidden py-[8px] text-sm font-semibold leading-5 text-secondary-grey/50">
            <Icon
              className="mr-[6px]"
              name={iconTypes[form.getValues(`populated_properties.${index}.property.type`) as PropertyType].icon}
            />
            {form.getValues(`populated_properties.${index}.property.name`)}
          </div>

          <CollectionPropertyValueControl propertyIndex={index} />
        </div>
      ))}
    </div>
  );
}

interface CollectionPropertyValueControlProps {
  propertyIndex: number;
}

function CollectionPropertyValueControl({ propertyIndex }: CollectionPropertyValueControlProps) {
  const form = useFormContext();
  const property = form.getValues(`populated_properties.${propertyIndex}.property`);

  switch (property.type) {
    case "text":
      return (
        <Controller
          control={form.control}
          name={`populated_properties.${propertyIndex}.value`}
          render={({ field }) => <TextEditor {...field} />}
        />
      );
    case "number":
      return (
        <input
          className="h-[36px] w-full rounded-md border border-[rgba(9,9,9,0.10)] px-[6px] py-[8px] text-base leading-5 text-black focus:outline-none"
          type="number"
          step="any"
          {...form.register(`populated_properties.${propertyIndex}.value`)}
        />
      );
    case "boolean":
      return (
        <Controller
          control={form.control}
          name={`populated_properties.${propertyIndex}.value`}
          render={({ field }) => (
            <input
              type="checkbox"
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      );
    case "date-time":
      return (
        <Controller
          control={form.control}
          name={`populated_properties.${propertyIndex}.value`}
          render={({ field }) => <DateTimePicker {...field} />}
        />
      );
  }

  return null;
}
