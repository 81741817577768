import { Link } from "react-router-dom";

import { useJSBridge } from "@/jsbridge";
import { UseAppSchemeURLOptions, useAppSchemeURL } from "@/lib/utils";

export interface AppLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  to: string;
  customAppSchemeURL?: string;
  children: React.ReactNode;
  className?: string;
  options?: UseAppSchemeURLOptions;
}

export default function AppLink({ to, customAppSchemeURL, children, className, options, ...props }: AppLinkProps) {
  const jsBridge = useJSBridge();
  const appSchemeURL = useAppSchemeURL(to, options);

  if (!jsBridge) {
    return (
      <Link to={to} className={className} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a href={customAppSchemeURL ?? appSchemeURL} className={className} {...props}>
      {children}
    </a>
  );
}
