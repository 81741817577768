import { useJSBridge } from "@/jsbridge";

export default function useLoading() {
  const jsbridge = useJSBridge();
  const showLoading = () => {
    jsbridge?.showLoading();
  };

  const hideLoading = () => {
    jsbridge?.hideLoading();
  };

  return {
    showLoading,
    hideLoading,
  };
}
