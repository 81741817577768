import { type ClassValue, clsx } from "clsx";
import { To, useHref } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface UseAppSchemeURLOptions {
  mode?: "push" | "present";
  preload?: boolean;
  popSelf?: boolean;
}

export function useAppSchemeURL(to: To, options?: UseAppSchemeURLOptions) {
  const href = useHref(to);
  const absoluteURL = new URL(href, location.href);
  const appSchemeURL = new URL("stackie://web");
  const searchParams = appSchemeURL.searchParams;
  searchParams.set("url", absoluteURL.toString());
  searchParams.set("hidden_navigation_bar", "true");
  searchParams.set("fit_safe_area", "false");
  if (options?.mode === "present") {
    searchParams.set("need_present", "true");
  }
  if (options?.preload !== false) {
    searchParams.set("preload", "true");
  }
  if (options?.popSelf) {
    searchParams.set("pop_self", "true");
  }
  return appSchemeURL.toString();
}

export function generateUniqueId() {
  // 获取当前时间戳
  const timestamp = Date.now().toString(36); // 转换为36进制字符串

  // 生成一个随机数并转换为36进制字符串
  const randomNum = Math.random().toString(36).substring(2);

  // 组合时间戳和随机数
  let uniqueId = timestamp + randomNum;

  // 如果生成的ID长度不足18位，继续添加随机数
  while (uniqueId.length < 18) {
    uniqueId += Math.random().toString(36).substring(2);
  }

  // 截取前18位
  uniqueId = uniqueId.substring(0, 18);

  return uniqueId;
}

export function exhaustiveCheck(value: never) {
  value;
}
