import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AnimatePresence, motion } from "framer-motion";
import { useReducer, useState } from "react";
import { useParams } from "react-router-dom";

import AppLink from "@/app/components/app-link";
import Icon from "@/app/components/icon";
import { deleteStack } from "@/generated";
import { useGoBack } from "@/hooks/use-goback";
import useLoading from "@/hooks/use-loading";
import useToast from "@/hooks/use-toast";
import { useJSBridge } from "@/jsbridge";

const Dropdown = () => {
  const params = useParams();
  const jsBridge = useJSBridge();
  const { showToast } = useToast();
  const { goBack } = useGoBack();
  const [animating, setAnimating] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const [open, setOpen] = useReducer((previousValue: boolean, newValue: boolean) => {
    if (animating) {
      return previousValue;
    }
    return newValue;
  }, false);

  const onDeleteStack = async () => {
    if (!jsBridge) {
      return;
    }

    const index = await jsBridge.alert({
      title: "Delete Stack?",
      message: "Are you sure you want to delete this stack?",
      actions: [
        {
          title: "Cancel",
          style: "cancel",
        },
        {
          title: "Delete",
          style: "destructive",
        },
      ],
    });

    if (index !== 1) {
      return;
    }

    try {
      showLoading();
      await deleteStack({
        stackId: Number(params.stackId),
      });
      hideLoading();
      goBack();
    } catch (error: any) {
      showToast(error.response.data.message);
      hideLoading();
    }
  };

  return (
    <div>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild>
          <button
            type="button"
            className="mx-[-16px] box-content flex h-full items-center justify-center px-[16px] outline-none"
          >
            <Icon name="More" width={24} height={24} />
          </button>
        </DropdownMenu.Trigger>
        <AnimatePresence>
          {open ? (
            <DropdownMenu.Portal forceMount>
              <DropdownMenu.Content className="z-50" align="end" asChild>
                <motion.div
                  className="mr-4 mt-3 min-w-44 origin-top-right rounded-xl bg-white shadow-dropdown-menu"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  onAnimationStart={() => setAnimating(true)}
                  onAnimationComplete={() => setAnimating(false)}
                >
                  <DropdownMenu.Item>
                    <AppLink
                      className="flex h-12 items-center whitespace-nowrap border-b-[0.5px] border-secondary-grey/20 px-4 active:bg-secondary-grey/5"
                      to={"/operations/editStack?stackId=" + params.stackId}
                    >
                      <Icon name="Pen" />
                      <span className="ml-3">Edit Stack</span>
                    </AppLink>
                  </DropdownMenu.Item>

                  <DropdownMenu.Item
                    onClick={onDeleteStack}
                    className="flex h-12 cursor-pointer items-center whitespace-nowrap px-4 active:bg-secondary-grey/5"
                  >
                    <Icon name="GarbageBlack" />
                    <span className="ml-3">Delete Stack</span>
                  </DropdownMenu.Item>
                </motion.div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          ) : null}
        </AnimatePresence>
      </DropdownMenu.Root>
    </div>
  );
};

export default Dropdown;
