import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA9okB0YV2MryxrV1alXoenzcY-aaN8TvM",
  authDomain: "stackie-c67a2.firebaseapp.com",
  projectId: "stackie-c67a2",
  storageBucket: "stackie-c67a2.appspot.com",
  messagingSenderId: "270891474774",
  appId: "1:270891474774:web:f72ce6247a55fd48c500f7",
  measurementId: "G-TXX49XZ21C",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

if (!import.meta.env.PROD) {
  // Optionally, you can disable analytics collection in development
  setAnalyticsCollectionEnabled(analytics, false);
}
export { app, analytics };
