import { forwardRef } from "react";

import { cn } from "@/lib/utils";

export type Edge = "top" | "right" | "bottom" | "left";

export interface SafeAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  edges?: Edge[];
}

export default forwardRef(function SafeArea(
  { edges = ["top", "right", "bottom", "left"], className, ...props }: SafeAreaProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cn(className, {
        "pl-safe-area-l": edges.includes("left"),
        "pr-safe-area-r": edges.includes("right"),
        "pt-safe-area-t": edges.includes("top"),
        "pb-safe-area-b": edges.includes("bottom"),
      })}
      {...props}
    />
  );
});
