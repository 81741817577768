import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import PageLayout from "@/app/components/subpage-layout";
import { useJSBridge } from "@/jsbridge";

import Loading from "./loading";

export default function CreateFromTemplatesLayout() {
  const jsBridge = useJSBridge();

  const closePage = () => {
    jsBridge?.closePage();
  };

  return (
    <PageLayout
      className="bg-white"
      left={
        <button type="button" className="m-[-8px] p-[8px]" onClick={closePage}>
          <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.45492 1.75199L1.2071 8.99985L8.45492 16.2477C8.47932 16.2721 8.50072 16.2984 8.51902 16.3262C8.64712 16.5203 8.62582 16.784 8.45492 16.9548C8.25972 17.1501 7.94312 17.1501 7.74782 16.9548L0.14644 9.35335C0.05267 9.25965 0 9.13245 0 8.99985C0 8.86725 0.05268 8.74005 0.14644 8.64625L7.74782 1.04488C7.94312 0.849623 8.25972 0.849623 8.45492 1.04488C8.47932 1.06929 8.50072 1.09559 8.51902 1.12331C8.64712 1.31739 8.62582 1.58113 8.45492 1.75199Z"
              fill="black"
            />
          </svg>
        </button>
      }
      header={
        <h1 className="min-w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-xl font-bold">
          Create from Templates
        </h1>
      }
    >
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
