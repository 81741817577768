import { useEffect, useMemo, useRef } from "react";
import Confetti from "react-confetti";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import { useIntersection, useWindowSize } from "react-use";

import { Button } from "@/app/components/button";
import { StackTemplateCategory, useListStackTemplatesSuspense } from "@/generated";
import { useJSBridge } from "@/jsbridge";
import { cn } from "@/lib/utils";

import TemplateGrid from "../components/template/template-grid";

export default function CreateFromTemplatesDonePage() {
  const [searchParams] = useSearchParams();
  const templates = useListStackTemplatesSuspense<StackTemplateCategory[]>();
  const { width, height } = useWindowSize();
  const jsBridge = useJSBridge();

  const scrollRootRef = useRef<HTMLDivElement>(null);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const sentinelIntersection = useIntersection(sentinelRef, { root: scrollRootRef.current });

  const choosenTemplateIds = useMemo(() => {
    const choosenTemplatesString = searchParams.get("templates");
    if (!choosenTemplatesString) {
      return [];
    }

    try {
      const choosenTemplates = JSON.parse(choosenTemplatesString);
      if (!Array.isArray(choosenTemplates)) {
        return [];
      }

      return choosenTemplates;
    } catch (e) {
      return [];
    }
  }, [searchParams]);

  const choosenTemplates = useMemo(() => {
    return choosenTemplateIds.flatMap((name) => {
      return templates.data?.find((template) => template.name === name) ?? [];
    });
  }, [choosenTemplateIds, templates]);

  useEffect(() => {
    jsBridge?.vibrate();
  }, [jsBridge]);

  const onDoneClick = () => {
    jsBridge?.closePage();
  };

  return (
    <div className="relative flex-1 pb-20">
      <h1 className="mx-auto mb-8 mt-20 max-w-[264px] text-center text-2xl font-bold leading-7 text-black duration-1000 animate-in fade-in slide-in-from-bottom-8 fill-mode-backwards">
        {choosenTemplates.length === 1 ? "Your stack is ready." : "Your stacks are ready."}
      </h1>

      <div
        ref={scrollRootRef}
        className={cn(
          "max-h-[206px] min-h-[130px] overflow-hidden duration-1000 animate-in fade-in slide-in-from-bottom-8 fill-mode-backwards",
          {
            "gradient-mask-b-0": !sentinelIntersection?.isIntersecting,
          },
        )}
        style={{ animationDelay: "500ms" }}
      >
        <TemplateGrid disabled templates={choosenTemplates} selectedTemplates={choosenTemplateIds} />
        <div ref={sentinelRef} />
      </div>

      <div
        className="mx-5 mt-6 text-center font-semibold leading-5 text-secondary-grey/70 duration-1000 animate-in fade-in slide-in-from-bottom-8 fill-mode-backwards"
        style={{ animationDelay: "1500ms" }}
      >
        Inbox is an extra stack to store your unclassifiable life snippets.
      </div>

      <section
        className="mx-5 mt-[10px] rounded-[10px] bg-[#f1f1f1] px-3 py-4 duration-1000 animate-in fade-in slide-in-from-bottom-8 fill-mode-backwards"
        style={{ animationDelay: "2000ms" }}
      >
        <header className="font-semibold leading-5 text-secondary-grey/70">📦 Inbox</header>
        <main className="mt-2 text-[13px] leading-[18px] text-secondary-grey/50">A piece of stray snippet 🧩</main>
      </section>

      <footer className="fixed bottom-0 left-0 right-0 pb-safe-area-b pl-safe-area-l pr-safe-area-r">
        <div className="p-4">
          <Button variant="outline" onClick={onDoneClick}>
            Done
          </Button>
        </div>
      </footer>

      {createPortal(<Confetti width={width} height={height} recycle={false} />, document.body)}
    </div>
  );
}
