import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ChevronRightIcon, PlusIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "@/app/components/icon";
import { StackOutputPropertiesItem } from "@/generated";
import useGoogleTracking from "@/hooks/use-google-tracking";
import { useJSBridge } from "@/jsbridge";
import { useAppSchemeURL } from "@/lib/utils";

interface DraggableListProps {
  className?: string;
  label?: string;
  stackId: number;
  list: StackOutputPropertiesItem[];
  onChange: (list: StackOutputPropertiesItem[]) => void;
  onDelete: (id: string) => void;
}

interface IDeletionStatus {
  [id: string]: boolean;
}

const DraggableList: React.FC<DraggableListProps> = ({ label, list, stackId, ...props }) => {
  const [properties, setProperties] = useState<StackOutputPropertiesItem[]>([]);
  const [deletionStatus, setDeletionStatus] = useState<IDeletionStatus>({});
  const [isEdit, setIsEdit] = useState(false);
  const appSchemeURL = useAppSchemeURL("/operations/editProperty");
  const jsbridge = useJSBridge();
  const navigator = useNavigate();
  const { trackCustomEvent } = useGoogleTracking();
  const iconTypes = {
    "date-time": "Calendar",
    number: "Number",
    boolean: "Complete",
    text: "Text",
  };

  useEffect(() => {
    setProperties(list);
  }, [list]);
  const onEdit = () => {
    const edit = !isEdit;
    if (!edit) {
      updateDeletionStatus();
    }
    setIsEdit(edit);
    trackCustomEvent(edit ? "property_edit_entry_button_click" : "property_save_edit_button_click", {
      stack_id: stackId,
    });
  };

  const onEditProperty = (id: string) => {
    if (id && deletionStatus[id]) {
      deletionStatus[id] = false;
      setDeletionStatus({ ...deletionStatus });
    } else {
      const hasDeleteValue = Object.values(deletionStatus).some((value) => value === true);
      if (!hasDeleteValue && !isEdit) {
        const item = list.find((v) => v.id == id);
        if (item) {
          navigateToPropertyEdit(item);
          trackCustomEvent("enter_property_detail", {
            stack_id: stackId,
            property_id: item.id,
          });
        }
      }
    }
  };

  const onAddProperty = () => {
    const propertyItem: StackOutputPropertiesItem = {
      id: "",
      name: "",
      prompt: "",
      required: false,
      default: null,
      type: "text",
    };
    navigateToPropertyEdit(propertyItem);
    trackCustomEvent("create_property_button_click", {
      stack_id: stackId,
    });
  };

  const navigateToPropertyEdit = (item: StackOutputPropertiesItem) => {
    localStorage.setItem("edit_property", JSON.stringify(item));
    if (jsbridge) {
      window.location.href = appSchemeURL;
    } else {
      navigator("/operations/editProperty");
    }
  };

  const updateDeletionStatus = (id?: string, isDeleted?: boolean) => {
    setDeletionStatus((prevState) => {
      const newState = Object.keys(prevState).reduce(
        (acc, key) => {
          acc[key] = false;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      if (id !== undefined && isDeleted !== undefined) {
        newState[id] = isDeleted;
      }
      return newState;
    });
  };

  const onShowDeleteProperty = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    updateDeletionStatus(id, true);
    event.stopPropagation();
  };

  const onDeleteProperty = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    props.onDelete(id);
    updateDeletionStatus(id, false);
    event.stopPropagation();
    trackCustomEvent("stack_property_delete_button_click", {
      stack_id: stackId,
      property_id: id,
    });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(properties);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProperties(items);
    props.onChange(items);
    trackCustomEvent("property_sort_count", {
      stack_id: stackId,
    });
  };

  return (
    <div>
      <div className="mb-2 flex justify-between self-stretch text-sm font-[590] leading-4 tracking-[-0.4px] text-[color:var(--font-gy-190,rgba(0,0,0,0.90))]">
        <div className="leading-none">{label}</div>
        <div onClick={onEdit}>
          {isEdit ? <div className="text-[color:var(--Primary-Orange,#FF6B00)]">Save</div> : <div>Edit</div>}
        </div>
      </div>
      <div className="characters overflow-hidden rounded-md bg-[#FFFFFF] pl-4 pt-1.5">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="" ref={provided.innerRef} {...provided.droppableProps}>
                {properties.map(({ id, name, type }, index) => {
                  return (
                    <Draggable isDragDisabled={!isEdit} key={id} draggableId={id as string} index={index}>
                      {(provided) => (
                        <motion.div animate={{ x: deletionStatus[id as string] ? -90 : 0 }}>
                          <li
                            className={`relative flex h-11 items-center justify-between gap-1.5 self-stretch border-b-[0.5px] border-solid border-b-[color:var(--secondary-grey-20,rgba(9,9,9,0.20))] px-0 py-2 ${deletionStatus[id as string] ? "" : "overflow-hidden"}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => onEditProperty(id)}
                          >
                            <div className="flex items-center">
                              {isEdit && (
                                <div className="flex h-11 items-center">
                                  <motion.div
                                    initial={{ width: 0, scale: 0 }}
                                    animate={{ width: 19, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0 }}
                                    transition={{ duration: 0.5, type: "spring" }}
                                    onClick={(event) => onShowDeleteProperty(event, id as string)}
                                  >
                                    <Icon name="Delete" width={19} height={20} />
                                  </motion.div>
                                </div>
                              )}

                              <Icon className="ml-2" name={iconTypes[type]} width={16} height={16} />
                              <p className="ml-1">{name}</p>
                            </div>
                            <div className="mr-4">
                              {isEdit ? (
                                <Icon name="Dragable" width={19} height={20} />
                              ) : (
                                <ChevronRightIcon width={22} height={22} color="rgba(60,60,67,0.30)" />
                              )}
                            </div>
                            <div
                              onClick={(event) => onDeleteProperty(event, id)}
                              className="absolute -right-[90px] flex h-11 w-[90px] items-center justify-center bg-red-500 text-white"
                            >
                              <span>删除</span>
                            </div>
                          </li>
                        </motion.div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div
          onClick={onAddProperty}
          className="flex h-11 items-center justify-center text-[13px] font-normal leading-[18px] tracking-[-0.4px] text-[color:var(--secondary-grey-70,rgba(9,9,9,0.70))]"
        >
          <PlusIcon />
          <span className="ml-1"> Add a property</span>
        </div>
      </div>
    </div>
  );
};

export default DraggableList;
