// useToast.ts
import { useJSBridge } from "@/jsbridge";

const useToast = () => {
  const jsBridge = useJSBridge();

  const showToast = (message: string) => {
    if (jsBridge) {
      jsBridge.toast(message);
    }
  };
  return { showToast };
};

export default useToast;
