import { useIsRestoring } from "@tanstack/react-query";

export interface PersistGateProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export default function PersistGate({ children, fallback = null }: PersistGateProps) {
  const isRestoring = useIsRestoring();

  return isRestoring ? fallback : children;
}
