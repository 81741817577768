import { AnimatePresence, motion } from "framer-motion";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Components, Virtuoso } from "react-virtuoso";

import StackPreview from "@/app/components/stack-preview";
import {
  CardWithStackResponseOutput,
  batchDeleteCards,
  useGetStackSuspense,
  useListCardsFromStackInfinite,
} from "@/generated";
import { useJSBridge } from "@/jsbridge";

import { useCardImages } from "../../params";
import Loading from "../loading/loading";
import Toolbar from "../toolbar";
import CardItem from "./card-item";

const pageSize = 20;

export interface CardListProps {
  stackId: number;
}

const List: Components["List"] = forwardRef(({ children, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <AnimatePresence mode="popLayout" initial={false}>
        {children}
      </AnimatePresence>
    </div>
  );
});

const Item: Components<CardWithStackResponseOutput>["Item"] = forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
  // An ugly workaround for the "Show More" / "Show Less" layout change animation at the first time
  const [delay, setDelay] = useState(-1);

  useEffect(() => {
    setDelay(0);
  }, []);

  return (
    <motion.div
      {...props}
      ref={ref}
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, delay, type: "spring" }}
    />
  );
});

export default function CardList({ stackId }: CardListProps) {
  const { data: stack } = useGetStackSuspense({ stackId });
  const [isChoose, setIsChoose] = useState(false);
  const [selectIds, setSelectedIds] = useState<number[]>([]);
  const jsBridge = useJSBridge();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useListCardsFromStackInfinite(
    { stackId },
    { page_size: pageSize },
    {
      query: {
        getNextPageParam: (lastPage) => {
          if (lastPage.length < pageSize) {
            return undefined;
          }

          return String(lastPage[lastPage.length - 1].id);
        },
        initialPageParam: null,
        throwOnError: true,
      },
    },
  );

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data.name == "noteCreated") {
        refetch();
      }
      if (event.data.name == "chooseNote") {
        setIsChoose((pre) => !pre);
        refetch();
      }
    };
    addEventListener("message", onMessage);
    return () => {
      removeEventListener("message", onMessage);
    };
  }, [refetch]);

  const cards = useMemo((): CardWithStackResponseOutput[] => {
    return (
      data?.pages
        .flatMap((page) => page)
        .map((v) => {
          v.populated_properties = v.populated_properties?.filter(
            (item) => item.value !== undefined && item.value !== null && item.value !== "",
          );
          return v;
        }) ?? []
    );
  }, [data?.pages]);

  useEffect(() => {
    setIsChoose(false);
  }, [cards]);

  const { cardImages } = useCardImages(cards);

  const loadNextPage = useCallback(() => {
    if (isFetchingNextPage) {
      return;
    }

    if (!hasNextPage) {
      return;
    }

    fetchNextPage();
  }, [isFetchingNextPage, fetchNextPage, hasNextPage]);

  const onSelectCard = (cardId: number, isSelect: boolean) => {
    if (isSelect) {
      selectIds.push(cardId);
    } else {
      const index = selectIds.findIndex((v) => v == cardId);
      if (index >= 0) {
        selectIds.splice(index, 1);
      }
    }
    setSelectedIds([...selectIds]);
  };

  const onMoveCards = async () => {
    if (!jsBridge) return;
    if (!selectIds.length) {
      return jsBridge.toast("please select a card");
    }
    await jsBridge.changeStack(JSON.stringify({ from_stack_id: stackId, from_card_ids: selectIds }));
    jsBridge.postNotification(JSON.stringify({ name: "webChooseNoteSuccess" }));
    setSelectedIds([]);
    refetch();
  };

  const onDeleteCards = async () => {
    if (!jsBridge) return;
    if (!selectIds.length) {
      return jsBridge.toast("please select a card");
    }
    try {
      await jsBridge.alert({
        title: "Delete Card?",
        message: "Are you sure you want to delete these cards?",
        actions: [
          {
            title: "Cancel",
            style: "cancel",
          },
          {
            title: "Delete",
            style: "destructive",
          },
        ],
      });
      jsBridge.showLoading();
      await batchDeleteCards(
        { stackId },
        {
          card_ids: selectIds,
        },
      );
      jsBridge.hideLoading();
      refetch();
      setIsChoose(false);
      setSelectedIds([]);
      jsBridge.postNotification(JSON.stringify({ name: "webChooseNoteSuccess" }));
    } catch (error: any) {
      jsBridge.toast(error.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!cards.length) {
    return <StackPreview className="mx-4" stack={stack} />;
  }

  return (
    <div>
      <Virtuoso
        useWindowScroll
        data={cards}
        endReached={loadNextPage}
        increaseViewportBy={200}
        itemContent={(_index, card) => {
          return (
            <div className="pb-3">
              <CardItem
                card={card}
                onSelect={onSelectCard}
                isChoose={isChoose}
                images={cardImages[card.id] || []}
                onMove={refetch}
              />
            </div>
          );
        }}
        computeItemKey={(_index, card) => card.id}
        components={{ List, Item }}
        initialItemCount={cards.length}
      />
      <Toolbar isVisible={isChoose} selectIds={selectIds} onMove={onMoveCards} onClose={onDeleteCards} />
    </div>
  );
}
