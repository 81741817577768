import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { CardWithStackResponseOutput, UserAttachmentResponse } from "@/generated";
import customInstance from "@/services/mutator/custom-instance";

export interface StackParams {
  stackId: string;
}

export default function useStackParams() {
  const { stackId: stackIdString } = useParams();

  const stackId = useMemo(() => {
    if (!stackIdString) {
      return null;
    }

    const stackId = parseInt(stackIdString, 10);
    if (isNaN(stackId)) {
      return null;
    }

    return stackId;
  }, [stackIdString]);

  if (!stackId) {
    throw new Error("Stack not found");
  }

  return { stackId };
}

interface CardParams {
  type: "image";
  uri: string;
  presigned_url: string;
}

export const useCardImages = (cards: CardWithStackResponseOutput[]) => {
  // 获取cardImages
  const [cardImages, setCardImages] = useState<{ [key: string]: CardParams[] }>({});
  const isLoadingImage = useRef(false);

  useEffect(() => {
    if (isLoadingImage.current) {
      return;
    }
    const fetchImages = async () => {
      isLoadingImage.current = true;
      const newImages: { [key: string]: CardParams[] } = {};
      for (const item of cards) {
        newImages[item.id] = [];
        for (const image of item.attachments) {
          try {
            const response: any = await getRealImageUrl(image.uri);

            newImages[item.id].push({
              type: image.type,
              uri: image.uri,
              presigned_url: response.presigned_url,
            });
          } catch (error) {
            console.error("Error fetching image:", error);
          }
        }
      }
      isLoadingImage.current = false;
      setCardImages(newImages);
    };
    fetchImages();
  }, [cards]);

  const getRealImageUrl = async (url: string) => {
    return await customInstance<UserAttachmentResponse>({ url: url + "?redirect=false&thumbnail=webp_144" });
  };

  return { cardImages, setCardImages, getRealImageUrl };
};
