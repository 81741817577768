import * as Sentry from "@sentry/react";
import { Suspense } from "react";
import { createHashRouter } from "react-router-dom";

import HomeLayout from "./app/(home)/layout.tsx";
import HomePage from "./app/(home)/page.tsx";
import ErrorBoundaryWithReload from "./app/components/error-boundary-with-reload.tsx";
import TrackingLayout from "./app/components/tracking-layout.tsx";
import { PreloadPage } from "./app/preload/page.tsx";
import EditPropertyPage from "./app/stack-operations/edit-property/page.tsx";
import EditStackPage from "./app/stack-operations/edit-stack/page.tsx";
import StackOperationsLayout from "./app/stack-operations/layout.tsx";
import NewStackPage from "./app/stack-operations/new-stack/page.tsx";
import EditCardPage from "./app/stacks/[stackId]/cards/[cardId]/edit/page.tsx";
import StackLayout from "./app/stacks/[stackId]/layout.tsx";
import StackPage from "./app/stacks/[stackId]/page.tsx";
import CreateFromTemplatesLayout from "./app/templates/(create)/layout.tsx";
import CreateFromTemplatesPage from "./app/templates/(create)/page.tsx";
import CreateFromTemplatesDoneLayout from "./app/templates/done/layout.tsx";
import CreateFromTemplatesDonePage from "./app/templates/done/page.tsx";
import Loading from "./loading.tsx";

const sentryCreateHashRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);

const router: ReturnType<typeof createHashRouter> = sentryCreateHashRouter([
  {
    element: (
      <ErrorBoundaryWithReload>
        <Suspense fallback={<Loading />}>
          <TrackingLayout />
        </Suspense>
      </ErrorBoundaryWithReload>
    ),
    children: [
      { path: "/preload", element: <PreloadPage /> },
      {
        path: "/",
        element: <HomeLayout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
        ],
      },
      {
        path: "/templates",
        element: <CreateFromTemplatesLayout />,
        children: [
          {
            path: "/templates",
            element: <CreateFromTemplatesPage />,
          },
        ],
      },
      {
        path: "/templates/done",
        element: <CreateFromTemplatesDoneLayout />,
        children: [
          {
            path: "/templates/done",
            element: <CreateFromTemplatesDonePage />,
          },
        ],
      },
      {
        path: "/stacks/:stackId",
        element: <StackLayout />,
        children: [
          {
            path: "/stacks/:stackId",
            element: <StackPage />,
          },
          {
            path: "/stacks/:stackId/cards/:cardId/edit",
            element: <EditCardPage />,
          },
        ],
      },
      {
        path: "/operations",
        element: <StackOperationsLayout />,
        children: [
          {
            path: "/operations/newStack",
            element: <NewStackPage />,
          },
          {
            path: "/operations/editStack",
            element: <EditStackPage />,
          },
          {
            path: "/operations/editProperty",
            element: <EditPropertyPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
