import React from "react";

import ArrowLeft from "@/app/assets/arrow-left.svg";
import ArrowRight from "@/app/assets/arrow-right.svg";
import Calendar from "@/app/assets/calendar.svg";
import Camera from "@/app/assets/camera.svg";
import Close from "@/app/assets/close.svg";
import Complete from "@/app/assets/complete.svg";
import Delete from "@/app/assets/delete.svg";
import Drag from "@/app/assets/drag.svg";
import Dragable from "@/app/assets/dragable.svg";
import GarbageBlack from "@/app/assets/garbage-black.svg";
import Garbage from "@/app/assets/garbage.svg";
import Image from "@/app/assets/image.svg";
import More from "@/app/assets/more.svg";
import Move from "@/app/assets/move.svg";
import Number from "@/app/assets/number.svg";
import Pen from "@/app/assets/pen.svg";
import Plus from "@/app/assets/plus.svg";
import Stack from "@/app/assets/stack.svg";
import Star from "@/app/assets/star.svg";
import Text from "@/app/assets/text.svg";

// 图标映射对象
const iconMap = {
  Drag,
  Star,
  Calendar,
  Complete,
  Number,
  Text,
  Delete,
  Dragable,
  ArrowRight,
  Garbage,
  More,
  Pen,
  GarbageBlack,
  ArrowLeft,
  Close,
  Plus,
  Camera,
  Image,
  Move,
  Stack,
};

type IconMap = typeof iconMap;
type KnownIconName = keyof IconMap; // 已知的图标名称

interface IconProps {
  name: KnownIconName | (string & NonNullable<unknown>);
  width?: number;
  height?: number;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ name, width = 16, height = 16, className = "" }) => {
  const SvgIcon = iconMap[name as KnownIconName] as unknown as React.FC<React.SVGProps<SVGSVGElement>>;

  if (!SvgIcon) {
    return null; // 或者返回一个默认的占位图标
  }

  return <SvgIcon width={width} height={height} className={className} />;
};

export default Icon;
