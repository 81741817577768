// src/components/Toolbar.tsx
import React from "react";

import { cn } from "@/lib/utils";

interface ToolbarProps {
  isVisible: boolean;
  selectIds: number[];
  onMove: () => void;
  onClose: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ isVisible, selectIds, onMove, onClose }) => {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 transform border-t-[color:var(--secondary-grey-20,rgba(9,9,9,0.20))] bg-white pb-[env(safe-area-inset-bottom)] shadow-lg transition-transform duration-300",
        {
          "translate-y-full": !isVisible,
          "translate-y-0": isVisible,
        },
      )}
    >
      <div className="flex justify-between p-4 px-6">
        <button
          disabled={!selectIds.length}
          className={cn({
            "text-[#272727] opacity-30": !selectIds.length,
            "text-[#080808]": selectIds.length,
          })}
          onClick={onMove}
        >
          Move
        </button>
        <button
          disabled={!selectIds.length}
          className={cn({
            "text-[#272727] opacity-30": !selectIds.length,
            "text-[#080808]": selectIds.length,
          })}
          onClick={onClose}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
