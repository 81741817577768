import { forwardRef, useCallback, useLayoutEffect, useRef } from "react";

export interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const TextEditor = forwardRef<HTMLTextAreaElement, TextEditorProps>(({ value, onChange }, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement>();

  const updateTextAreaSize = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateTextAreaSize);
    return () => window.removeEventListener("resize", updateTextAreaSize);
  }, [updateTextAreaSize]);

  useLayoutEffect(() => {
    updateTextAreaSize();
  }, [value, updateTextAreaSize]);

  const setRef = (node: HTMLTextAreaElement) => {
    textAreaRef.current = node;
    if (typeof ref === "function") {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  return (
    <textarea
      ref={setRef}
      className="min-h-[80px] w-full rounded-md border border-[rgba(9,9,9,0.10)] px-[6px] py-[8px] text-base leading-5 text-black focus:outline-none"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
});

export default TextEditor;
