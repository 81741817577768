import { useCallback } from "react";
import { Fragment } from "react/jsx-runtime";

import { Button } from "@/app/components/button";
import PropertyValue from "@/app/components/property-value";
import SafeArea from "@/app/components/safe-area";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@/app/components/sheet";
import { PropertyWithValueOutput, StackTemplate } from "@/generated";
import { cn } from "@/lib/utils";

export interface TemplateItemProps {
  className?: string;
  template: StackTemplate;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export default function TemplateItem({ className, template, onCheckedChange }: TemplateItemProps) {
  const onChoose = useCallback(() => {
    onCheckedChange?.(true);
  }, [onCheckedChange]);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div
          className={cn("relative flex h-24 gap-y-[6px] rounded-xl px-3 pb-3 pt-[10px]", className)}
          style={{ backgroundColor: template.theme_color_palette.light }}
        >
          <div className="text-sm font-semibold leading-5 text-black">{template.emoji}</div>
          <div className="ml-[6px] mt-[2px] line-clamp-3 text-ellipsis break-words text-sm font-semibold leading-4 text-black">
            {template.name}
          </div>
        </div>
      </SheetTrigger>
      <SheetContent
        side="bottom"
        className="flex max-h-[calc(100vh-64px)] flex-col border-none"
        style={{
          backgroundColor: template.theme_color_palette.light,
        }}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <SafeArea edges={["left", "right", "bottom"]} className="flex min-h-0 flex-1 flex-col gap-y-4">
          <div className="me-8 flex gap-x-2">
            <div className="text-xl font-semibold leading-6">{template.emoji}</div>
            <div className="line-clamp-2 min-w-0 flex-1 text-ellipsis break-words text-xl font-semibold leading-6">
              {template.name}
            </div>
          </div>

          <div className="mx-1 break-words text-[13px] leading-[18px] text-black empty:hidden">{template.prompt}</div>

          <section className="flex max-h-[244px] min-h-0 flex-1 flex-col overflow-auto rounded-2xl bg-[rgba(255,255,255,0.60)] px-4 py-4">
            <main className="grid min-h-0 flex-1 grid-cols-[minmax(0,2fr)_3fr] gap-x-3 gap-y-4 overflow-auto">
              {template.properties.map((property) => (
                <Fragment key={property.id}>
                  <div className="break-words text-sm font-semibold leading-4 text-secondary-grey/70">
                    {property.name}
                  </div>
                  <div className="leading-5 text-secondary-grey/20">
                    <div className="">
                      <PropertyValue
                        property={property}
                        value={template.example[property.name] as PropertyWithValueOutput["value"]}
                      />
                    </div>
                  </div>
                </Fragment>
              ))}
            </main>
          </section>

          <SheetClose asChild>
            <Button variant="secondary" onClick={onChoose}>
              Use Template
            </Button>
          </SheetClose>
        </SafeArea>
      </SheetContent>
    </Sheet>
  );
}
