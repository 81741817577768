import SafeArea from "@/app/components/safe-area";

const Loading = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full">
      <SafeArea edges={["left", "right", "bottom"]}>
        <div className="flex h-[50px] items-center justify-center text-center text-[11px] font-semibold text-secondary-grey/70">
          Syncing…
        </div>
      </SafeArea>
    </div>
  );
};

export default Loading;
