import { useEffect } from "react";

export interface ThemeColorProviderProps {
  themeColor: string;
  children: React.ReactNode;
}

export default function ThemeColorProvider({ themeColor, children }: ThemeColorProviderProps) {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--theme-color", themeColor);

    return () => {
      root.style.removeProperty("--theme-color");
    };
  }, [themeColor]);

  return <>{children}</>;
}
