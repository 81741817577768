/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Orion App API
 * OpenAPI spec version: 0.1.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import pingMutator from '../services/mutator/custom-instance';
import type { ErrorType as PingErrorType } from '../services/mutator/custom-instance';
import pingHeadMutator from '../services/mutator/custom-instance';
import type { ErrorType as PingHeadErrorType } from '../services/mutator/custom-instance';
import logoutMutator from '../services/mutator/custom-instance';
import type { ErrorType as LogoutErrorType } from '../services/mutator/custom-instance';
import listStackTemplatesMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListStackTemplatesErrorType } from '../services/mutator/custom-instance';
import createStacksFromTemplateMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateStacksFromTemplateErrorType } from '../services/mutator/custom-instance';
import createStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateStackErrorType } from '../services/mutator/custom-instance';
import listStacksMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListStacksErrorType } from '../services/mutator/custom-instance';
import listDraftStackExamplesMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListDraftStackExamplesErrorType } from '../services/mutator/custom-instance';
import draftStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as DraftStackErrorType } from '../services/mutator/custom-instance';
import editStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as EditStackErrorType } from '../services/mutator/custom-instance';
import getStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetStackErrorType } from '../services/mutator/custom-instance';
import deleteStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteStackErrorType } from '../services/mutator/custom-instance';
import updateAppSettingsMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAppSettingsErrorType } from '../services/mutator/custom-instance';
import listCardsFromStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListCardsFromStackErrorType } from '../services/mutator/custom-instance';
import createCardMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateCardErrorType } from '../services/mutator/custom-instance';
import batchDeleteCardsMutator from '../services/mutator/custom-instance';
import type { ErrorType as BatchDeleteCardsErrorType } from '../services/mutator/custom-instance';
import getCardMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetCardErrorType } from '../services/mutator/custom-instance';
import editCardMutator from '../services/mutator/custom-instance';
import type { ErrorType as EditCardErrorType } from '../services/mutator/custom-instance';
import deleteCardMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteCardErrorType } from '../services/mutator/custom-instance';
import changeCardStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as ChangeCardStackErrorType } from '../services/mutator/custom-instance';
import batchMoveCardsMutator from '../services/mutator/custom-instance';
import type { ErrorType as BatchMoveCardsErrorType } from '../services/mutator/custom-instance';
import searchCardsMutator from '../services/mutator/custom-instance';
import type { ErrorType as SearchCardsErrorType } from '../services/mutator/custom-instance';
import stackieSendFeedbackMutator from '../services/mutator/custom-instance';
import type { ErrorType as StackieSendFeedbackErrorType } from '../services/mutator/custom-instance';
import generateTurnIntoStackDraftStackExamplesMutator from '../services/mutator/custom-instance';
import type { ErrorType as GenerateTurnIntoStackDraftStackExamplesErrorType } from '../services/mutator/custom-instance';
import turnIntoStackDraftMutator from '../services/mutator/custom-instance';
import type { ErrorType as TurnIntoStackDraftErrorType } from '../services/mutator/custom-instance';
import turnIntoStackMutator from '../services/mutator/custom-instance';
import type { ErrorType as TurnIntoStackErrorType } from '../services/mutator/custom-instance';
export type TurnIntoStackPathParameters = {
 stackId: number,
 }
export type TurnIntoStackDraftPathParameters = {
 stackId: number,
 }
export type GenerateTurnIntoStackDraftStackExamplesPathParameters = {
 stackId: number,
 }
export type SearchCardsParams = {
q: string;
stack_ids?: number[] | null;
};

export type BatchMoveCardsPathParameters = {
 stackId: number,
 }
export type DeleteCardPathParameters = {
 stackId: number,
    cardId: number,
 }
export type EditCardPathParameters = {
 stackId: number,
    cardId: number,
 }
export type GetCardPathParameters = {
 stackId: number,
    cardId: number,
 }
export type BatchDeleteCardsPathParameters = {
 stackId: number,
 }
export type CreateCardPathParameters = {
 stackId: number,
 }
export type ListCardsFromStackParams = {
before?: string | null;
after?: string | null;
page_size?: number;
};

export type ListCardsFromStackPathParameters = {
 stackId: number,
 }
export type DeleteStackPathParameters = {
 stackId: number,
 }
export type GetStackPathParameters = {
 stackId: number,
 }
export type EditStackPathParameters = {
 stackId: number,
 }
export type ListStacksParams = {
keywords?: string | null;
before?: string | null;
after?: string | null;
page_size?: number;
};

export type ListStackTemplates200 = StackTemplate[] | StackTemplateCategory[];

export type ListStackTemplatesParams = {
group_by_category?: boolean;
};

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

/**
 * An enumeration.
 */
export type UserType = typeof UserType[keyof typeof UserType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserType = {
  user: 'user',
  group: 'group',
  bot: 'bot',
} as const;

export type UserSettingsWebapp = { [key: string]: unknown };

export type UserSettingsProperties = {[key: string]: string};

export interface UserSettings {
  calendar_conflict_checker_enabled?: boolean;
  calendar_default_alert_minutes?: number;
  calendar_default_duration_minutes?: number;
  clock_format?: ClockFormat;
  daily_notification_enabled?: boolean;
  daily_notification_hour?: number;
  daily_notification_minute?: number;
  daily_weather_notification_enabled?: boolean;
  feed_recom_enabled?: boolean;
  im_notification_enabled?: boolean;
  language?: string;
  monthly_release_notification_enabled?: boolean;
  nickname?: string;
  nickname_enabled?: boolean;
  persona?: string;
  persona_key?: PersonaKey;
  properties?: UserSettingsProperties;
  /** All recall notifications' switch (include lifecycle push) */
  recall_notification_enabled?: boolean;
  starting_workflow?: string;
  time_format?: TimeFormat;
  timezone?: string;
  units?: Units;
  webapp?: UserSettingsWebapp;
  whatsapp_compatibility_mode?: boolean;
}

/**
 * An enumeration.
 */
export type UserRole = typeof UserRole[keyof typeof UserRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  user: 'user',
  admin: 'admin',
  tester: 'tester',
  group_member: 'group_member',
  group_admin: 'group_admin',
} as const;

export type UserProfileZoneinfo = string | null;

export type UserProfileWebsite = string | null;

export type UserProfileUpdatedAt = number | null;

export type UserProfileProfile = string | null;

export type UserProfilePreferredUsername = string | null;

export type UserProfilePicture = string | null;

export type UserProfilePhoneNumberVerified = boolean | null;

export type UserProfilePhoneNumber = string | null;

export type UserProfileNickname = string | null;

export type UserProfileName = string | null;

export type UserProfileMiddleName = string | null;

export type UserProfileLocale = string | null;

export type UserProfileGivenName = string | null;

export type UserProfileGender = string | null;

export type UserProfileFamilyName = string | null;

export type UserProfileEmailVerified = boolean | null;

export type UserProfileEmail = string | null;

export type UserProfileBirthdate = string | null;

export type UserProfileAddress = ProfileAddress | null;

/**
 * The user info returned by the OAuth provider.
https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
 */
export interface UserProfile {
  address?: UserProfileAddress;
  birthdate?: UserProfileBirthdate;
  email?: UserProfileEmail;
  email_verified?: UserProfileEmailVerified;
  family_name?: UserProfileFamilyName;
  gender?: UserProfileGender;
  given_name?: UserProfileGivenName;
  locale?: UserProfileLocale;
  middle_name?: UserProfileMiddleName;
  name?: UserProfileName;
  nickname?: UserProfileNickname;
  phone_number?: UserProfilePhoneNumber;
  phone_number_verified?: UserProfilePhoneNumberVerified;
  picture?: UserProfilePicture;
  preferred_username?: UserProfilePreferredUsername;
  profile?: UserProfileProfile;
  sub: string;
  updated_at?: UserProfileUpdatedAt;
  website?: UserProfileWebsite;
  zoneinfo?: UserProfileZoneinfo;
}

export interface UserInfoResponse {
  calendars: UserCalendarsInfo;
  created_at: number;
  feature_sets?: string[];
  roles: UserRole[];
  settings: UserSettings;
}

export interface UserCalendarsItem {
  id: string;
  name: string;
}

export interface UserCalendarsAccount {
  enabled_cals?: UserCalendarsItem[];
  id: string;
  /** Frendly name of the calendar, like google email, show in UI */
  name: string;
  provider: CalendarProviderNameOutput;
  /** The last time the calendar names were synchronized. */
  synced_at?: number;
}

export interface UserCalendarsInfo {
  accounts?: UserCalendarsAccount[];
  default_calendar?: CalendarItemWithAccount;
}

export interface UserAttachmentResponse {
  presigned_url: string;
}

/**
 * An enumeration.
 */
export type Units = typeof Units[keyof typeof Units];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Units = {
  metric: 'metric',
  imperial: 'imperial',
} as const;

export interface TurnIntoStackResponse {
  cards: CardWithStack[];
  stack: StackOutput;
}

export type TurnIntoStackRequestConversationId = string | null;

export interface TurnIntoStackRequest {
  conversation_id?: TurnIntoStackRequestConversationId;
  create_stack_params: CreateStackRequestInput;
  move_cards_params: TurnIntoStackMoveCardRequestInput[];
}

export interface TurnIntoStackMoveCardRequestOutput {
  populated_properties: PropertyWithValueOutput[];
  source_card: CardWithStackResponseOutput;
}

export interface TurnIntoStackMoveCardRequestInput {
  populated_properties: PropertyWithValueInput[];
  source_card: CardWithStackResponseInput;
}

export interface TurnIntoStackDraftResponse {
  create_stack_params: CreateStackRequestOutput;
  move_cards_params: TurnIntoStackMoveCardRequestOutput[];
}

export interface TurnIntoStackDraftRequest {
  card_ids: number[];
  prompt?: string;
}

export interface TurnIntoStackDraftExamplesRequest {
  card_ids: number[];
}

export type TokenTokenType = string | null;

export type TokenRefreshToken = string | null;

export type TokenIdToken = string | null;

/**
 * https://datatracker.ietf.org/doc/html/rfc6749#section-5.1
 */
export interface Token {
  access_token: string;
  expires_in: number;
  id_token?: TokenIdToken;
  refresh_token?: TokenRefreshToken;
  token_type?: TokenTokenType;
}

/**
 * An enumeration.
 */
export type TimeFormat = typeof TimeFormat[keyof typeof TimeFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeFormat = {
  '': '',
  '%I:%M_%p': '%I:%M %p',
  '%H:%M': '%H:%M',
  '%-I:%M_%p': '%-I:%M %p',
  '%-H:%M': '%-H:%M',
} as const;

export type TextPropertyWithValueOutputValue = string | null;

export interface TextPropertyWithValueOutput {
  property: TextPropertyOutput;
  value: TextPropertyWithValueOutputValue;
}

export type TextPropertyWithValueInputValue = string | null;

export type TextPropertyOutputType = typeof TextPropertyOutputType[keyof typeof TextPropertyOutputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextPropertyOutputType = {
  text: 'text',
} as const;

export type TextPropertyOutputDefault = string | null;

export interface TextPropertyOutput {
  default: TextPropertyOutputDefault;
  id: string;
  /** @minLength 1 */
  name: string;
  prompt: string;
  required: boolean;
  type: TextPropertyOutputType;
}

export type TextPropertyInputType = typeof TextPropertyInputType[keyof typeof TextPropertyInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextPropertyInputType = {
  text: 'text',
} as const;

export type TextPropertyInputDefault = string | null;

export interface TextPropertyInput {
  default?: TextPropertyInputDefault;
  id?: string;
  /** @minLength 1 */
  name: string;
  prompt?: string;
  required?: boolean;
  type?: TextPropertyInputType;
}

export interface TextPropertyWithValueInput {
  property: TextPropertyInput;
  value: TextPropertyWithValueInputValue;
}

export type StackieTextMessageType = typeof StackieTextMessageType[keyof typeof StackieTextMessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieTextMessageType = {
  text: 'text',
} as const;

export interface StackieTextMessage {
  /** @maxItems 5 */
  attachments?: StackieMessageImageAttachment[];
  content: string;
  type: StackieTextMessageType;
}

export type StackieResponseMessageContentTextType = typeof StackieResponseMessageContentTextType[keyof typeof StackieResponseMessageContentTextType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieResponseMessageContentTextType = {
  text: 'text',
} as const;

/**
 * This is a plain text message.
 */
export interface StackieResponseMessageContentText {
  content: string;
  heading: StackieResponseMessageContentTextHeading;
  type: StackieResponseMessageContentTextType;
}

export interface StackieResponseMessageContentComponentHeading {
  title: string;
}

export type StackieResponseMessageContentTextHeading = StackieResponseMessageContentComponentHeading | null;

export type StackieResponseMessageContentCardsType = typeof StackieResponseMessageContentCardsType[keyof typeof StackieResponseMessageContentCardsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieResponseMessageContentCardsType = {
  cards: 'cards',
} as const;

export type StackieResponseMessageContentCardsHeading = StackieResponseMessageContentComponentHeading | null;

/**
 * This is a message with one or more cards.
 */
export interface StackieResponseMessageContentCards {
  cards: CardWithStackAndMetadata[];
  heading: StackieResponseMessageContentCardsHeading;
  type: StackieResponseMessageContentCardsType;
}

export type StackieResponseMessageRole = typeof StackieResponseMessageRole[keyof typeof StackieResponseMessageRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieResponseMessageRole = {
  assistant: 'assistant',
} as const;

export type StackieResponseMessageContent = StackieResponseMessageContentText | StackieResponseMessageContentCards;

/**
 * Represents a response message from the Stackie workflow.
 */
export interface StackieResponseMessage {
  content: StackieResponseMessageContent;
  created_at: string;
  message_id: string;
  role: StackieResponseMessageRole;
  updated_at: string;
}

export type StackieMessageImageAttachmentType = typeof StackieMessageImageAttachmentType[keyof typeof StackieMessageImageAttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieMessageImageAttachmentType = {
  image: 'image',
} as const;

export interface StackieMessageImageAttachment {
  type: StackieMessageImageAttachmentType;
  uri: string;
}

export interface StackieDraftExample {
  label: string;
  prompt: string;
}

export type StackieConversationSourceStackDetailsType = typeof StackieConversationSourceStackDetailsType[keyof typeof StackieConversationSourceStackDetailsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieConversationSourceStackDetailsType = {
  stack_details: 'stack_details',
} as const;

export interface StackieConversationSourceStackDetails {
  stack_id: number;
  type?: StackieConversationSourceStackDetailsType;
}

export type StackieConversationSourceHomeType = typeof StackieConversationSourceHomeType[keyof typeof StackieConversationSourceHomeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieConversationSourceHomeType = {
  home: 'home',
} as const;

export interface StackieConversationSourceHome {
  type?: StackieConversationSourceHomeType;
}

export interface StackieConversationSendMessageResponse {
  conversation_id: string;
  messages: StackieResponseMessage[];
}

/**
 * The source of the new conversation. The source in a conversation should be the same for all messages in the conversation. Providing a different source is an undefined behavior.
 */
export type StackieConversationSendMessageRequestConversationSource = StackieConversationSourceHome | StackieConversationSourceStackDetails;

/**
 * ID of the conversation to start or send a message to. If not provided, a new conversation will be created.
 */
export type StackieConversationSendMessageRequestConversationId = string | null;

export interface StackieConversationSendMessageRequest {
  /** ID of the conversation to start or send a message to. If not provided, a new conversation will be created. */
  conversation_id?: StackieConversationSendMessageRequestConversationId;
  /** The source of the new conversation. The source in a conversation should be the same for all messages in the conversation. Providing a different source is an undefined behavior. */
  conversation_source?: StackieConversationSendMessageRequestConversationSource;
  /** Message to send, either an audio message or a text message with optional attachments. */
  message: StackieConversationSendMessageRequestMessage;
  /** Whether to stream the response back to the client. If set to true, the response will be sent as a stream of events, with each event representing a message in the conversation. The client should use the `Accept: text/event-stream` header to receive the stream. */
  stream?: boolean;
  stream_cycle?: number;
}

export type StackieConversationPingEventType = typeof StackieConversationPingEventType[keyof typeof StackieConversationPingEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieConversationPingEventType = {
  ping: 'ping',
} as const;

/**
 * This event is to keep the connection alive.
 */
export interface StackieConversationPingEvent {
  conversation_id: string;
  type: StackieConversationPingEventType;
}

export type StackieConversationMessageEventType = typeof StackieConversationMessageEventType[keyof typeof StackieConversationMessageEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieConversationMessageEventType = {
  message: 'message',
} as const;

/**
 * This event is to send or update a message to the client.

The client should:
1. Check if the message with the same ID is already present on the screen.
2. If the message is already present, update the message.
3. If the message is not present, insert the message to the screen.

The messages should be sorted by the `created_at` field in descending order.
 */
export interface StackieConversationMessageEvent {
  conversation_id: string;
  message: StackieResponseMessage;
  type: StackieConversationMessageEventType;
}

export type StackieAudioMessageType = typeof StackieAudioMessageType[keyof typeof StackieAudioMessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackieAudioMessageType = {
  audio: 'audio',
} as const;

export interface StackieAudioMessage {
  type: StackieAudioMessageType;
  uri: string;
}

/**
 * Message to send, either an audio message or a text message with optional attachments.
 */
export type StackieConversationSendMessageRequestMessage = StackieAudioMessage | StackieTextMessage;

export type StackWithCardResponsePropertiesItem = TextPropertyOutput | NumberPropertyOutput | BooleanPropertyOutput | DateTimePropertyOutput;

export type StackWithCardResponseCard = CardWithStackResponseOutput | null;

export type StackType = typeof StackType[keyof typeof StackType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StackType = {
  inbox: 'inbox',
  collection: 'collection',
} as const;

export interface StackWithCardResponse {
  card: StackWithCardResponseCard;
  created_at: string;
  emoji: string;
  id: number;
  name: string;
  prompt: string;
  properties: StackWithCardResponsePropertiesItem[];
  theme_color: string;
  theme_color_palette: ColorPalette;
  total_cards: number;
  readonly type: StackType;
  updated_at: string;
}

export type StackTemplateExample = { [key: string]: unknown };

export interface StackTemplate {
  emoji: string;
  example: StackTemplateExample;
  id: string;
  is_created: boolean;
  name: string;
  prompt: string;
  properties: Properties;
  theme_color: string;
  theme_color_palette: ColorPalette;
}

export interface StackTemplateCategory {
  name: string;
  templates: StackTemplate[];
}

export type StackOutputPropertiesItem = TextPropertyOutput | NumberPropertyOutput | BooleanPropertyOutput | DateTimePropertyOutput;

export interface StackOutput {
  created_at: string;
  emoji: string;
  id: number;
  name: string;
  prompt: string;
  properties: StackOutputPropertiesItem[];
  theme_color: string;
  theme_color_palette: ColorPalette;
  readonly type: StackType;
  updated_at: string;
}

export type StackInputPropertiesItem = TextPropertyInput | NumberPropertyInput | BooleanPropertyInput | DateTimePropertyInput;

export interface StackInput {
  created_at: string;
  emoji: string;
  id: number;
  name: string;
  prompt: string;
  properties: StackInputPropertiesItem[];
  theme_color: string;
  theme_color_palette: ColorPalette;
  updated_at: string;
}

/**
 * An enumeration.
 */
export type SourceOutput = typeof SourceOutput[keyof typeof SourceOutput];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SourceOutput = {
  unknown: 'unknown',
  wechat: 'wechat',
  line: 'line',
  admin_web: 'admin_web',
  api: 'api',
  telegram: 'telegram',
  whatsapp: 'whatsapp',
  imessage: 'imessage',
  web: 'web',
  app: 'app',
  chat: 'chat',
  push: 'push',
} as const;

export type SourceInput = typeof SourceInput[keyof typeof SourceInput];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SourceInput = {
  unknown: 'unknown',
  wechat: 'wechat',
  line: 'line',
  admin_web: 'admin_web',
  api: 'api',
  telegram: 'telegram',
  whatsapp: 'whatsapp',
  imessage: 'imessage',
  web: 'web',
  app: 'app',
  chat: 'chat',
  push: 'push',
} as const;

export interface SharedEventAttendeeInfo {
  avatar_url?: string;
  is_me?: boolean;
  language?: string;
  name: string;
  source?: SourceOutput;
  timezone?: string;
  type: UserType;
}

export interface SharedEventInfo {
  attendees?: SharedEventAttendeeInfo[];
  organizer: SharedEventAttendeeInfo;
  share_key: string;
}

export type SettingsRequestTimezone = string | null;

export type SettingsRequestRegionCode = string | null;

export type SettingsRequestMeasurementSystem = string | null;

export type SettingsRequestLanguage = string | null;

export interface SettingsRequest {
  language?: SettingsRequestLanguage;
  measurement_system?: SettingsRequestMeasurementSystem;
  region_code?: SettingsRequestRegionCode;
  timezone?: SettingsRequestTimezone;
}

export type SendStackieFeedbackRequestFeedbackSource = string | null;

export type SendStackieFeedbackRequestEmail = string | null;

export interface SendStackieFeedbackRequest {
  content: string;
  email?: SendStackieFeedbackRequestEmail;
  feedback_source?: SendStackieFeedbackRequestFeedbackSource;
}

export interface SearchCardsResponse {
  cards: CardWithStackResponseOutput[];
}

/**
 * An enumeration.
 */
export type RelativeOffsetUnit = typeof RelativeOffsetUnit[keyof typeof RelativeOffsetUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelativeOffsetUnit = {
  minute: 'minute',
  hour: 'hour',
  day: 'day',
} as const;

/**
 * The end date of the recurrence end, or null if the recurrence end is count-based.
 */
export type RecurrenceEndEndDate = string | string;

export interface RecurrenceEnd {
  /** The end date of the recurrence end, or null if the recurrence end is count-based. */
  end_date?: RecurrenceEndEndDate;
  /** The occurrence count of the recurrence end, or 0 if the recurrence end is date-based. */
  occurrence_count?: number;
}

export interface RecurrenceRulesOutput {
  /** The days of the month associated with the recurrence rule, values can be from 1 to 31 and from -1 to -31. Negative values indicate counting backwards from the end of the month. */
  days_of_the_month?: number[];
  /** The days of the nth week associated with the recurrence rule. */
  days_of_the_nth_week?: NthWeekday[];
  /** The days of the week associated with the recurrence rule. */
  days_of_the_week?: DaysEnum[];
  /** The frequency of the recurrence rule. */
  frequency?: FrequencyEnum;
  /** A positive integer representing at which intervals the recurrence rule repeats.For example, within a DAILY rule, a value of 8 means every eight days. */
  interval?: number;
  /** The months of the year associated with the recurrence rule. */
  months_of_the_year?: number[];
  /** For remove recurrence rules ONLY, not for output */
  none?: boolean;
  /** Defines the end of a recurrence rule */
  recurrence_end?: RecurrenceEnd;
  /** For initialize from rrule string ONLY, not for output */
  rrule?: string;
}

export interface RecurrenceRulesInput {
  /** The days of the month associated with the recurrence rule, values can be from 1 to 31 and from -1 to -31. Negative values indicate counting backwards from the end of the month. */
  days_of_the_month?: number[];
  /** The days of the nth week associated with the recurrence rule. */
  days_of_the_nth_week?: NthWeekday[];
  /** The days of the week associated with the recurrence rule. */
  days_of_the_week?: DaysEnum[];
  /** The frequency of the recurrence rule. */
  frequency?: FrequencyEnum;
  /** A positive integer representing at which intervals the recurrence rule repeats.For example, within a DAILY rule, a value of 8 means every eight days. */
  interval?: number;
  /** The months of the year associated with the recurrence rule. */
  months_of_the_year?: number[];
  /** For remove recurrence rules ONLY, not for output */
  none?: boolean;
  /** Defines the end of a recurrence rule */
  recurrence_end?: RecurrenceEnd;
  /** For initialize from rrule string ONLY, not for output */
  rrule?: string;
}

export type PropertyWithValueOutput = TextPropertyWithValueOutput | NumberPropertyWithValueOutput | BooleanPropertyWithValueOutput | DateTimePropertyWithValueOutput;

export type PropertyWithValueInput = TextPropertyWithValueInput | NumberPropertyWithValueInput | BooleanPropertyWithValueInput | DateTimePropertyWithValueInput;

export type PropertiesItem = TextPropertyOutput | NumberPropertyOutput | BooleanPropertyOutput | DateTimePropertyOutput;

export type Properties = PropertiesItem[];

export type ProfileAddressStreetAddress = string | null;

export type ProfileAddressRegion = string | null;

export type ProfileAddressPostalCode = string | null;

export type ProfileAddressLocality = string | null;

export type ProfileAddressFormatted = string | null;

export type ProfileAddressCountry = string | null;

export interface ProfileAddress {
  country?: ProfileAddressCountry;
  formatted?: ProfileAddressFormatted;
  locality?: ProfileAddressLocality;
  postal_code?: ProfileAddressPostalCode;
  region?: ProfileAddressRegion;
  street_address?: ProfileAddressStreetAddress;
}

export type PrepareUploadResponseFields = {[key: string]: string};

export interface PrepareUploadResponse {
  fields: PrepareUploadResponseFields;
  file_key: string;
  file_url: string;
  url: string;
}

export type PrepareUploadRequestContentType = typeof PrepareUploadRequestContentType[keyof typeof PrepareUploadRequestContentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrepareUploadRequestContentType = {
  'image/png': 'image/png',
  'image/jpeg': 'image/jpeg',
  'image/gif': 'image/gif',
  'image/webp': 'image/webp',
  'audio/aac': 'audio/aac',
  'audio/ogg': 'audio/ogg',
  'audio/webm': 'audio/webm',
  'audio/x-m4a': 'audio/x-m4a',
} as const;

export type PrepareUploadRequestClientId = string | null;

export interface PrepareUploadRequest {
  client_id?: PrepareUploadRequestClientId;
  /**
   * @maximum 10485760
   */
  content_length: number;
  content_type: PrepareUploadRequestContentType;
  filename: string;
}

/**
 * An enumeration.
 */
export type PersonaKey = typeof PersonaKey[keyof typeof PersonaKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonaKey = {
  PERSONA_DEFAULT: 'PERSONA_DEFAULT',
  PERSONA_CUTE_GIRL: 'PERSONA_CUTE_GIRL',
  PERSONA_PERFECT_SISTER: 'PERSONA_PERFECT_SISTER',
  PERSONA_BEST_BUTLER: 'PERSONA_BEST_BUTLER',
  PERSONA_SASSY_LASSY_GAL: 'PERSONA_SASSY_LASSY_GAL',
  PERSONA_MAFIA_BOSS: 'PERSONA_MAFIA_BOSS',
} as const;

export type OAuthTokenExchangeRequestGrantType = typeof OAuthTokenExchangeRequestGrantType[keyof typeof OAuthTokenExchangeRequestGrantType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OAuthTokenExchangeRequestGrantType = {
  'urn:ietf:params:oauth:grant-type:token-exchange': 'urn:ietf:params:oauth:grant-type:token-exchange',
} as const;

export interface OAuthTokenExchangeRequest {
  audience?: string;
  client_id: string;
  grant_type: OAuthTokenExchangeRequestGrantType;
  scope?: string;
  subject_token: string;
  subject_token_type: string;
  /** ONLY FOR `APPLE-AUTHZ-CODE` */
  user_profile?: string;
}

export type NumberPropertyWithValueOutputValue = string | null;

export interface NumberPropertyWithValueOutput {
  property: NumberPropertyOutput;
  value: NumberPropertyWithValueOutputValue;
}

export type NumberPropertyWithValueInputValue = number | string | null;

export interface NumberPropertyWithValueInput {
  property: NumberPropertyInput;
  value: NumberPropertyWithValueInputValue;
}

export type NumberPropertyOutputType = typeof NumberPropertyOutputType[keyof typeof NumberPropertyOutputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberPropertyOutputType = {
  number: 'number',
} as const;

export type NumberPropertyOutputDefault = string | null;

export interface NumberPropertyOutput {
  default: NumberPropertyOutputDefault;
  id: string;
  /** @minLength 1 */
  name: string;
  prompt: string;
  required: boolean;
  type: NumberPropertyOutputType;
}

export type NumberPropertyInputType = typeof NumberPropertyInputType[keyof typeof NumberPropertyInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberPropertyInputType = {
  number: 'number',
} as const;

export type NumberPropertyInputDefault = number | string | null;

export interface NumberPropertyInput {
  default?: NumberPropertyInputDefault;
  id?: string;
  /** @minLength 1 */
  name: string;
  prompt?: string;
  required?: boolean;
  type?: NumberPropertyInputType;
}

export interface NthWeekday {
  nth: number;
  weekday: DaysEnum;
}

export interface MoveCardsRequest {
  card_ids: number[];
  to_stack_id: number;
}

export interface LogoutRequest {
  client_id: string;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

/**
 * An enumeration.
 */
export type FrequencyEnum = typeof FrequencyEnum[keyof typeof FrequencyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FrequencyEnum = {
  hourly: 'hourly',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
} as const;

export interface EventTime {
  date?: string;
  datetime?: string;
  timezone?: string;
}

export interface EnableAccountRequest {
  default_calendar_id: string;
  enabled_calendar_ids?: string[];
  move_events?: boolean;
}

export type EditStackRequestThemeColor = string | null;

export type EditStackRequestPropertiesAnyOfItem = TextPropertyInput | NumberPropertyInput | BooleanPropertyInput | DateTimePropertyInput;

export type EditStackRequestProperties = EditStackRequestPropertiesAnyOfItem[] | null;

export type EditStackRequestPrompt = string | null;

export type EditStackRequestName = string | null;

export type EditStackRequestEmoji = string | null;

export interface EditStackRequest {
  emoji?: EditStackRequestEmoji;
  name?: EditStackRequestName;
  prompt?: EditStackRequestPrompt;
  properties?: EditStackRequestProperties;
  theme_color?: EditStackRequestThemeColor;
}

export type EditCardRequestProperties = { [key: string]: unknown };

export type EditCardRequestConversationId = string | null;

export interface EditCardRequest {
  attachments?: CardAttachment[];
  conversation_id?: EditCardRequestConversationId;
  properties: EditCardRequestProperties;
}

export interface DraftStackRequest {
  prompt: string;
}

export type DolaPatchEventRequestStartAt = string | string;

export type DolaPatchEventRequestEndAt = string | string;

export interface DolaPatchEventRequest {
  alarm_via_phone?: boolean;
  alarms?: Alarm[];
  /** Move event to calendar, must be one of `user_info.calendars.default_calendar.account.enabled_cals.*.id` */
  calendar_id?: string;
  emoji?: string;
  end?: EventTime;
  end_at?: DolaPatchEventRequestEndAt;
  location?: string;
  notes?: string;
  /** Only works when patch entire event or include_future=True, when patch single instance will ignore this field */
  recurrence_rules?: RecurrenceRulesInput;
  start?: EventTime;
  start_at?: DolaPatchEventRequestStartAt;
  title?: string;
}

export interface DolaCreateEventRequest {
  alarms?: Alarm[];
  calendar_id?: string;
  emoji?: string;
  end: EventTime;
  location?: string;
  notes?: string;
  recurrence_rules?: RecurrenceRulesInput;
  start: EventTime;
  title: string;
}

export type DolaCalendarEventRecurrenceId = string | string;

export interface DolaCalendarEvent {
  alarm_via_phone?: boolean;
  alarms?: Alarm[];
  calendar?: UserCalendarsItem;
  emoji?: string;
  end: EventTime;
  is_all_day: boolean;
  location?: string;
  notes?: string;
  recurrence_id?: DolaCalendarEventRecurrenceId;
  recurrence_rules?: RecurrenceRulesOutput;
  share?: SharedEventInfo;
  start: EventTime;
  title: string;
  uid: string;
}

export interface DolaCalendarAccountInfo {
  calendars: CalendarInfo[];
  default_calendar_id?: string;
  enabled_calendar_ids?: string[];
}

export interface DolaBindAccountResponse {
  action?: string;
  url: string;
}

export type DeviceRegistrationOsVersion = string | null;

export type DeviceRegistrationModel = string | null;

export type DeviceRegistrationFcmRegistrationToken = string | null;

export type DeviceRegistrationApnsDeviceToken = string | null;

export type DevicePlatform = typeof DevicePlatform[keyof typeof DevicePlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DevicePlatform = {
  ios: 'ios',
  android: 'android',
} as const;

export interface DeviceRegistration {
  apns_device_token?: DeviceRegistrationApnsDeviceToken;
  client_id: string;
  client_version: string;
  device_id: string;
  fcm_registration_token?: DeviceRegistrationFcmRegistrationToken;
  model?: DeviceRegistrationModel;
  os_version?: DeviceRegistrationOsVersion;
  platform: DevicePlatform;
}

export interface DeviceCallToken {
  provider: string;
  token: string;
}

export interface DeviceBaseInfo {
  client_id: string;
  device_id: string;
  platform: DevicePlatform;
}

export interface DeleteUserRequest { [key: string]: unknown }

export type DeleteCardRequestConversationId = string | null;

export interface DeleteCardRequest {
  conversation_id?: DeleteCardRequestConversationId;
}

/**
 * An enumeration.
 */
export type DaysEnum = typeof DaysEnum[keyof typeof DaysEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DaysEnum = {
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
} as const;

export type DateTimePropertyWithValueOutputValue = string | null;

export type DateTimePropertyWithValueInputValue = string | null;

export interface DateTimePropertyWithValueInput {
  property: DateTimePropertyInput;
  value: DateTimePropertyWithValueInputValue;
}

export type DateTimePropertyOutputType = typeof DateTimePropertyOutputType[keyof typeof DateTimePropertyOutputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimePropertyOutputType = {
  'date-time': 'date-time',
} as const;

export type DateTimePropertyOutputDefault = string | null;

export interface DateTimePropertyOutput {
  default: DateTimePropertyOutputDefault;
  id: string;
  /** @minLength 1 */
  name: string;
  prompt: string;
  required: boolean;
  type: DateTimePropertyOutputType;
}

export interface DateTimePropertyWithValueOutput {
  property: DateTimePropertyOutput;
  value: DateTimePropertyWithValueOutputValue;
}

export type DateTimePropertyInputType = typeof DateTimePropertyInputType[keyof typeof DateTimePropertyInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimePropertyInputType = {
  'date-time': 'date-time',
} as const;

export type DateTimePropertyInputDefault = string | null;

export interface DateTimePropertyInput {
  default?: DateTimePropertyInputDefault;
  id?: string;
  /** @minLength 1 */
  name: string;
  prompt?: string;
  required?: boolean;
  type?: DateTimePropertyInputType;
  use_now_func?: boolean;
}

export type CreateStackRequestOutputThemeColor = string | null;

export type CreateStackRequestOutputPropertiesItem = TextPropertyOutput | NumberPropertyOutput | BooleanPropertyOutput | DateTimePropertyOutput;

export interface CreateStackRequestOutput {
  emoji: string;
  name: string;
  prompt: string;
  properties: CreateStackRequestOutputPropertiesItem[];
  rename_on_duplicate: boolean;
  theme_color: CreateStackRequestOutputThemeColor;
  readonly theme_color_palette: ColorPalette;
}

export type CreateStackRequestInputThemeColor = string | null;

export type CreateStackRequestInputPropertiesItem = TextPropertyInput | NumberPropertyInput | BooleanPropertyInput | DateTimePropertyInput;

export interface CreateStackRequestInput {
  emoji?: string;
  name: string;
  prompt?: string;
  properties: CreateStackRequestInputPropertiesItem[];
  rename_on_duplicate?: boolean;
  theme_color?: CreateStackRequestInputThemeColor;
}

export interface CreateStackFromTemplateRequest {
  templates: string[];
}

export type CreateCardRequestProperties = { [key: string]: unknown };

export interface CreateCardRequest {
  attachments?: CardAttachment[];
  properties: CreateCardRequestProperties;
}

export interface ColorPalette {
  dark: string;
  light: string;
}

/**
 * An enumeration.
 */
export type ClockFormat = typeof ClockFormat[keyof typeof ClockFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClockFormat = {
  '12h': '12h',
  '24h': '24h',
} as const;

export type ChangeCardRequestConversationId = string | null;

export interface ChangeCardRequest {
  conversation_id?: ChangeCardRequestConversationId;
  from_card_id: number;
  from_stack_id: number;
  to_stack_id: number;
}

export type CardWithStackResponseOutputProperties = { [key: string]: unknown };

export interface CardWithStackResponseOutput {
  attachments: CardAttachment[];
  created_at: string;
  id: number;
  populated_properties: PropertyWithValueOutput[];
  properties: CardWithStackResponseOutputProperties;
  stack: StackOutput;
  updated_at: string;
}

export type CardWithStackResponseInputProperties = { [key: string]: unknown };

export interface CardWithStackResponseInput {
  attachments: CardAttachment[];
  created_at: string;
  id: number;
  populated_properties?: PropertyWithValueInput[];
  properties?: CardWithStackResponseInputProperties;
  stack: StackInput;
  updated_at: string;
}

export interface CardWithStackAndMetadata {
  attachments: CardAttachment[];
  created_at: string;
  id: number;
  metadata: CardWithStackAndMetadataMetadata;
  readonly populated_properties: readonly PropertyWithValueOutput[];
  stack: StackOutput;
  updated_at: string;
}

export interface CardWithStack {
  attachments: CardAttachment[];
  created_at: string;
  id: number;
  readonly populated_properties: readonly PropertyWithValueOutput[];
  stack: StackOutput;
  updated_at: string;
}

export interface CardMetadata { [key: string]: unknown }

export type CardWithStackAndMetadataMetadata = CardMetadata | null;

export type CardAttachmentType = typeof CardAttachmentType[keyof typeof CardAttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardAttachmentType = {
  image: 'image',
} as const;

export interface CardAttachment {
  type: CardAttachmentType;
  uri: string;
}

/**
 * An enumeration.
 */
export type CalendarProviderNameOutput = typeof CalendarProviderNameOutput[keyof typeof CalendarProviderNameOutput];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarProviderNameOutput = {
  self_hosted: 'self_hosted',
  google: 'google',
  icloud: 'icloud',
  feishu: 'feishu',
  outlook: 'outlook',
  custom: 'custom',
} as const;

export type CalendarProviderNameInput = typeof CalendarProviderNameInput[keyof typeof CalendarProviderNameInput];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarProviderNameInput = {
  self_hosted: 'self_hosted',
  google: 'google',
  icloud: 'icloud',
  feishu: 'feishu',
  outlook: 'outlook',
  custom: 'custom',
} as const;

export interface CalendarItemWithAccount {
  account: UserCalendarsAccount;
  id: string;
  name: string;
}

export interface CalendarInfo {
  color?: string;
  id: string;
  name?: string;
  url?: string;
}

export type BooleanPropertyWithValueOutputValue = boolean | null;

export interface BooleanPropertyWithValueOutput {
  property: BooleanPropertyOutput;
  value: BooleanPropertyWithValueOutputValue;
}

export type BooleanPropertyWithValueInputValue = boolean | null;

export interface BooleanPropertyWithValueInput {
  property: BooleanPropertyInput;
  value: BooleanPropertyWithValueInputValue;
}

export type BooleanPropertyOutputType = typeof BooleanPropertyOutputType[keyof typeof BooleanPropertyOutputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BooleanPropertyOutputType = {
  boolean: 'boolean',
} as const;

export type BooleanPropertyOutputDefault = boolean | null;

export interface BooleanPropertyOutput {
  default: BooleanPropertyOutputDefault;
  id: string;
  /** @minLength 1 */
  name: string;
  prompt: string;
  required: boolean;
  type: BooleanPropertyOutputType;
}

export type BooleanPropertyInputType = typeof BooleanPropertyInputType[keyof typeof BooleanPropertyInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BooleanPropertyInputType = {
  boolean: 'boolean',
} as const;

export type BooleanPropertyInputDefault = boolean | null;

export interface BooleanPropertyInput {
  default?: BooleanPropertyInputDefault;
  id?: string;
  /** @minLength 1 */
  name: string;
  prompt?: string;
  required?: boolean;
  type?: BooleanPropertyInputType;
}

export interface BatchDeleteCardsRequest {
  card_ids: number[];
}

export type AppSystemUnknownErrorCode = typeof AppSystemUnknownErrorCode[keyof typeof AppSystemUnknownErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppSystemUnknownErrorCode = {
  NUMBER_1: 1,
} as const;

export interface AppSystemUnknownError {
  code?: AppSystemUnknownErrorCode;
  message?: string;
}

export type AppRequestValidationErrorCode = typeof AppRequestValidationErrorCode[keyof typeof AppRequestValidationErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRequestValidationErrorCode = {
  NUMBER_1001: 1001,
} as const;

export interface AppRequestValidationError {
  code?: AppRequestValidationErrorCode;
  message?: string;
}

export type AppRequestUnauthorizedErrorCode = typeof AppRequestUnauthorizedErrorCode[keyof typeof AppRequestUnauthorizedErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRequestUnauthorizedErrorCode = {
  NUMBER_1002: 1002,
} as const;

export interface AppRequestUnauthorizedError {
  code?: AppRequestUnauthorizedErrorCode;
  message?: string;
}

export type AppRequestPermissionDeniedErrorCode = typeof AppRequestPermissionDeniedErrorCode[keyof typeof AppRequestPermissionDeniedErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRequestPermissionDeniedErrorCode = {
  NUMBER_1003: 1003,
} as const;

export interface AppRequestPermissionDeniedError {
  code?: AppRequestPermissionDeniedErrorCode;
  message?: string;
}

export interface Alarm {
  absolute_time?: string;
  /** The relative offset, negative means before the event, positive means after the event */
  relative_offset?: number;
  relative_offset_unit?: RelativeOffsetUnit;
}





/**
 * @summary Ping
 */
export const ping = (
    
 signal?: AbortSignal
) => {
      
      
      return pingMutator<unknown>(
      {url: `/ping`, method: 'GET', signal
    },
      );
    }
  

export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: QueryKey }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Ping
 */
export const usePing = <TData = Awaited<ReturnType<typeof ping>>, TError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: QueryKey }
}

export type PingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingSuspenseQueryError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Ping
 */
export const usePingSuspense = <TData = Awaited<ReturnType<typeof ping>>, TError = PingErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ping Head
 */
export const pingHead = (
    
 signal?: AbortSignal
) => {
      
      
      return pingHeadMutator<unknown>(
      {url: `/ping`, method: 'HEAD', signal
    },
      );
    }
  


export const getPingHeadMutationOptions = <TError = PingHeadErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pingHead>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof pingHead>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pingHead>>, void> = () => {
          

          return  pingHead()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PingHeadMutationResult = NonNullable<Awaited<ReturnType<typeof pingHead>>>
    
    export type PingHeadMutationError = PingHeadErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Ping Head
 */
export const usePingHead = <TError = PingHeadErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pingHead>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof pingHead>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPingHeadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Logout the user.

It will invalidate the access token and revoke the refresh token.
 * @summary Logout
 */
export const logout = (
    logoutRequest: LogoutRequest,
 ) => {
      
      
      return logoutMutator<void>(
      {url: `/logout`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: logoutRequest
    },
      );
    }
  


export const getLogoutMutationOptions = <TError = LogoutErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,{data: LogoutRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,{data: LogoutRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof logout>>, {data: LogoutRequest}> = (props) => {
          const {data} = props ?? {};

          return  logout(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LogoutMutationResult = NonNullable<Awaited<ReturnType<typeof logout>>>
    export type LogoutMutationBody = LogoutRequest
    export type LogoutMutationError = LogoutErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Logout
 */
export const useLogout = <TError = LogoutErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,{data: LogoutRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof logout>>,
        TError,
        {data: LogoutRequest},
        TContext
      > => {

      const mutationOptions = getLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Stack Templates
 */
export const listStackTemplates = (
    params?: ListStackTemplatesParams,
 signal?: AbortSignal
) => {
      
      
      return listStackTemplatesMutator<ListStackTemplates200>(
      {url: `/stackie/templates`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListStackTemplatesQueryKey = (params?: ListStackTemplatesParams,) => {
    return [`/stackie/templates`, ...(params ? [params]: [])] as const;
    }

    
export const getListStackTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof listStackTemplates>>, TError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params?: ListStackTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStackTemplatesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStackTemplates>>> = ({ signal }) => listStackTemplates(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type ListStackTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof listStackTemplates>>>
export type ListStackTemplatesQueryError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Stack Templates
 */
export const useListStackTemplates = <TData = Awaited<ReturnType<typeof listStackTemplates>>, TError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params?: ListStackTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStackTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListStackTemplatesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listStackTemplates>>, TError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params?: ListStackTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStackTemplatesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStackTemplates>>> = ({ signal }) => listStackTemplates(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type ListStackTemplatesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listStackTemplates>>>
export type ListStackTemplatesSuspenseQueryError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Stack Templates
 */
export const useListStackTemplatesSuspense = <TData = Awaited<ReturnType<typeof listStackTemplates>>, TError = ListStackTemplatesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params?: ListStackTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStackTemplates>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStackTemplatesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Stacks From Template
 */
export const createStacksFromTemplate = (
    createStackFromTemplateRequest: CreateStackFromTemplateRequest,
 ) => {
      
      
      return createStacksFromTemplateMutator<StackWithCardResponse[]>(
      {url: `/stackie/create_stacks_from_template`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createStackFromTemplateRequest
    },
      );
    }
  


export const getCreateStacksFromTemplateMutationOptions = <TError = CreateStacksFromTemplateErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStacksFromTemplate>>, TError,{data: CreateStackFromTemplateRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createStacksFromTemplate>>, TError,{data: CreateStackFromTemplateRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStacksFromTemplate>>, {data: CreateStackFromTemplateRequest}> = (props) => {
          const {data} = props ?? {};

          return  createStacksFromTemplate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateStacksFromTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createStacksFromTemplate>>>
    export type CreateStacksFromTemplateMutationBody = CreateStackFromTemplateRequest
    export type CreateStacksFromTemplateMutationError = CreateStacksFromTemplateErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Create Stacks From Template
 */
export const useCreateStacksFromTemplate = <TError = CreateStacksFromTemplateErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStacksFromTemplate>>, TError,{data: CreateStackFromTemplateRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createStacksFromTemplate>>,
        TError,
        {data: CreateStackFromTemplateRequest},
        TContext
      > => {

      const mutationOptions = getCreateStacksFromTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Stack
 */
export const createStack = (
    createStackRequestInput: CreateStackRequestInput,
 ) => {
      
      
      return createStackMutator<StackWithCardResponse>(
      {url: `/stackie/stacks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createStackRequestInput
    },
      );
    }
  


export const getCreateStackMutationOptions = <TError = CreateStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStack>>, TError,{data: CreateStackRequestInput}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createStack>>, TError,{data: CreateStackRequestInput}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStack>>, {data: CreateStackRequestInput}> = (props) => {
          const {data} = props ?? {};

          return  createStack(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateStackMutationResult = NonNullable<Awaited<ReturnType<typeof createStack>>>
    export type CreateStackMutationBody = CreateStackRequestInput
    export type CreateStackMutationError = CreateStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Create Stack
 */
export const useCreateStack = <TError = CreateStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStack>>, TError,{data: CreateStackRequestInput}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createStack>>,
        TError,
        {data: CreateStackRequestInput},
        TContext
      > => {

      const mutationOptions = getCreateStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Stacks
 */
export const listStacks = (
    params?: ListStacksParams,
 signal?: AbortSignal
) => {
      
      
      return listStacksMutator<StackWithCardResponse[]>(
      {url: `/stackie/stacks`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListStacksQueryKey = (params?: ListStacksParams,) => {
    return [`/stackie/stacks`, ...(params ? [params]: [])] as const;
    }

    
export const getListStacksInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listStacks>>, ListStacksParams['after']>, TError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params?: ListStacksParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData, Awaited<ReturnType<typeof listStacks>>, QueryKey, ListStacksParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStacksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStacks>>, QueryKey, ListStacksParams['after']> = ({ signal, pageParam }) => listStacks({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData, Awaited<ReturnType<typeof listStacks>>, QueryKey, ListStacksParams['after']> & { queryKey: QueryKey }
}

export type ListStacksInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listStacks>>>
export type ListStacksInfiniteQueryError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Stacks
 */
export const useListStacksInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listStacks>>, ListStacksParams['after']>, TError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params?: ListStacksParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData, Awaited<ReturnType<typeof listStacks>>, QueryKey, ListStacksParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStacksInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListStacksSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listStacks>>, TError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params?: ListStacksParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStacksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStacks>>> = ({ signal }) => listStacks(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData> & { queryKey: QueryKey }
}

export type ListStacksSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listStacks>>>
export type ListStacksSuspenseQueryError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Stacks
 */
export const useListStacksSuspense = <TData = Awaited<ReturnType<typeof listStacks>>, TError = ListStacksErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params?: ListStacksParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listStacks>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStacksSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Draft Stack Examples
 */
export const listDraftStackExamples = (
    
 signal?: AbortSignal
) => {
      
      
      return listDraftStackExamplesMutator<StackieDraftExample[]>(
      {url: `/stackie/stacks/draft/examples`, method: 'GET', signal
    },
      );
    }
  

export const getListDraftStackExamplesQueryKey = () => {
    return [`/stackie/stacks/draft/examples`] as const;
    }

    
export const getListDraftStackExamplesQueryOptions = <TData = Awaited<ReturnType<typeof listDraftStackExamples>>, TError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDraftStackExamplesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDraftStackExamples>>> = ({ signal }) => listDraftStackExamples(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDraftStackExamplesQueryResult = NonNullable<Awaited<ReturnType<typeof listDraftStackExamples>>>
export type ListDraftStackExamplesQueryError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Draft Stack Examples
 */
export const useListDraftStackExamples = <TData = Awaited<ReturnType<typeof listDraftStackExamples>>, TError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDraftStackExamplesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListDraftStackExamplesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listDraftStackExamples>>, TError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDraftStackExamplesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDraftStackExamples>>> = ({ signal }) => listDraftStackExamples(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDraftStackExamplesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listDraftStackExamples>>>
export type ListDraftStackExamplesSuspenseQueryError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Draft Stack Examples
 */
export const useListDraftStackExamplesSuspense = <TData = Awaited<ReturnType<typeof listDraftStackExamples>>, TError = ListDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDraftStackExamples>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDraftStackExamplesSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Draft Stack
 */
export const draftStack = (
    draftStackRequest: DraftStackRequest,
 ) => {
      
      
      return draftStackMutator<CreateStackRequestOutput>(
      {url: `/stackie/stacks/draft`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: draftStackRequest
    },
      );
    }
  


export const getDraftStackMutationOptions = <TError = DraftStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof draftStack>>, TError,{data: DraftStackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof draftStack>>, TError,{data: DraftStackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof draftStack>>, {data: DraftStackRequest}> = (props) => {
          const {data} = props ?? {};

          return  draftStack(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DraftStackMutationResult = NonNullable<Awaited<ReturnType<typeof draftStack>>>
    export type DraftStackMutationBody = DraftStackRequest
    export type DraftStackMutationError = DraftStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Draft Stack
 */
export const useDraftStack = <TError = DraftStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof draftStack>>, TError,{data: DraftStackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof draftStack>>,
        TError,
        {data: DraftStackRequest},
        TContext
      > => {

      const mutationOptions = getDraftStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Edit Stack
 */
export const editStack = (
    { stackId }: EditStackPathParameters,
    editStackRequest: EditStackRequest,
 ) => {
      
      
      return editStackMutator<StackWithCardResponse>(
      {url: `/stackie/stacks/${stackId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: editStackRequest
    },
      );
    }
  


export const getEditStackMutationOptions = <TError = EditStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editStack>>, TError,{pathParams: EditStackPathParameters;data: EditStackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof editStack>>, TError,{pathParams: EditStackPathParameters;data: EditStackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editStack>>, {pathParams: EditStackPathParameters;data: EditStackRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  editStack(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditStackMutationResult = NonNullable<Awaited<ReturnType<typeof editStack>>>
    export type EditStackMutationBody = EditStackRequest
    export type EditStackMutationError = EditStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>

    /**
 * @summary Edit Stack
 */
export const useEditStack = <TError = EditStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editStack>>, TError,{pathParams: EditStackPathParameters;data: EditStackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof editStack>>,
        TError,
        {pathParams: EditStackPathParameters;data: EditStackRequest},
        TContext
      > => {

      const mutationOptions = getEditStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Stack
 */
export const getStack = (
    { stackId }: GetStackPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getStackMutator<StackOutput>(
      {url: `/stackie/stacks/${stackId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetStackQueryKey = ({ stackId }: GetStackPathParameters,) => {
    return [`/stackie/stacks/${stackId}`] as const;
    }

    
export const getGetStackQueryOptions = <TData = Awaited<ReturnType<typeof getStack>>, TError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId }: GetStackPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStackQueryKey({ stackId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStack>>> = ({ signal }) => getStack({ stackId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStackQueryResult = NonNullable<Awaited<ReturnType<typeof getStack>>>
export type GetStackQueryError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Get Stack
 */
export const useGetStack = <TData = Awaited<ReturnType<typeof getStack>>, TError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId }: GetStackPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStackQueryOptions({ stackId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetStackSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getStack>>, TError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId }: GetStackPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStackQueryKey({ stackId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStack>>> = ({ signal }) => getStack({ stackId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStackSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getStack>>>
export type GetStackSuspenseQueryError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Get Stack
 */
export const useGetStackSuspense = <TData = Awaited<ReturnType<typeof getStack>>, TError = GetStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId }: GetStackPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getStack>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStackSuspenseQueryOptions({ stackId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Stack
 */
export const deleteStack = (
    { stackId }: DeleteStackPathParameters,
 ) => {
      
      
      return deleteStackMutator<unknown>(
      {url: `/stackie/stacks/${stackId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteStackMutationOptions = <TError = DeleteStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStack>>, TError,{pathParams: DeleteStackPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteStack>>, TError,{pathParams: DeleteStackPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStack>>, {pathParams: DeleteStackPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteStack(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteStackMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStack>>>
    
    export type DeleteStackMutationError = DeleteStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Delete Stack
 */
export const useDeleteStack = <TError = DeleteStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStack>>, TError,{pathParams: DeleteStackPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteStack>>,
        TError,
        {pathParams: DeleteStackPathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update App Settings
 */
export const updateAppSettings = (
    settingsRequest: SettingsRequest,
 ) => {
      
      
      return updateAppSettingsMutator<unknown>(
      {url: `/stackie/settings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: settingsRequest
    },
      );
    }
  


export const getUpdateAppSettingsMutationOptions = <TError = UpdateAppSettingsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAppSettings>>, TError,{data: SettingsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAppSettings>>, TError,{data: SettingsRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAppSettings>>, {data: SettingsRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateAppSettings(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAppSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAppSettings>>>
    export type UpdateAppSettingsMutationBody = SettingsRequest
    export type UpdateAppSettingsMutationError = UpdateAppSettingsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Update App Settings
 */
export const useUpdateAppSettings = <TError = UpdateAppSettingsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAppSettings>>, TError,{data: SettingsRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAppSettings>>,
        TError,
        {data: SettingsRequest},
        TContext
      > => {

      const mutationOptions = getUpdateAppSettingsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Cards From Stack
 */
export const listCardsFromStack = (
    { stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams,
 signal?: AbortSignal
) => {
      
      
      return listCardsFromStackMutator<CardWithStackResponseOutput[]>(
      {url: `/stackie/stacks/${stackId}/cards`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListCardsFromStackQueryKey = ({ stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams,) => {
    return [`/stackie/stacks/${stackId}/cards`, ...(params ? [params]: [])] as const;
    }

    
export const getListCardsFromStackInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listCardsFromStack>>, ListCardsFromStackParams['after']>, TError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData, Awaited<ReturnType<typeof listCardsFromStack>>, QueryKey, ListCardsFromStackParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCardsFromStackQueryKey({ stackId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCardsFromStack>>, QueryKey, ListCardsFromStackParams['after']> = ({ signal, pageParam }) => listCardsFromStack({ stackId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData, Awaited<ReturnType<typeof listCardsFromStack>>, QueryKey, ListCardsFromStackParams['after']> & { queryKey: QueryKey }
}

export type ListCardsFromStackInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCardsFromStack>>>
export type ListCardsFromStackInfiniteQueryError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Cards From Stack
 */
export const useListCardsFromStackInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listCardsFromStack>>, ListCardsFromStackParams['after']>, TError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData, Awaited<ReturnType<typeof listCardsFromStack>>, QueryKey, ListCardsFromStackParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCardsFromStackInfiniteQueryOptions({ stackId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListCardsFromStackSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listCardsFromStack>>, TError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCardsFromStackQueryKey({ stackId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCardsFromStack>>> = ({ signal }) => listCardsFromStack({ stackId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCardsFromStackSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listCardsFromStack>>>
export type ListCardsFromStackSuspenseQueryError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary List Cards From Stack
 */
export const useListCardsFromStackSuspense = <TData = Awaited<ReturnType<typeof listCardsFromStack>>, TError = ListCardsFromStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId }: ListCardsFromStackPathParameters,
    params?: ListCardsFromStackParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCardsFromStack>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCardsFromStackSuspenseQueryOptions({ stackId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Card
 */
export const createCard = (
    { stackId }: CreateCardPathParameters,
    createCardRequest: CreateCardRequest,
 ) => {
      
      
      return createCardMutator<CardWithStackResponseOutput>(
      {url: `/stackie/stacks/${stackId}/cards`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCardRequest
    },
      );
    }
  


export const getCreateCardMutationOptions = <TError = CreateCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{pathParams: CreateCardPathParameters;data: CreateCardRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{pathParams: CreateCardPathParameters;data: CreateCardRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCard>>, {pathParams: CreateCardPathParameters;data: CreateCardRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  createCard(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCardMutationResult = NonNullable<Awaited<ReturnType<typeof createCard>>>
    export type CreateCardMutationBody = CreateCardRequest
    export type CreateCardMutationError = CreateCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Create Card
 */
export const useCreateCard = <TError = CreateCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{pathParams: CreateCardPathParameters;data: CreateCardRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createCard>>,
        TError,
        {pathParams: CreateCardPathParameters;data: CreateCardRequest},
        TContext
      > => {

      const mutationOptions = getCreateCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Delete Cards
 */
export const batchDeleteCards = (
    { stackId }: BatchDeleteCardsPathParameters,
    batchDeleteCardsRequest: BatchDeleteCardsRequest,
 ) => {
      
      
      return batchDeleteCardsMutator<void>(
      {url: `/stackie/stacks/${stackId}/cards`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteCardsRequest
    },
      );
    }
  


export const getBatchDeleteCardsMutationOptions = <TError = BatchDeleteCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteCards>>, TError,{pathParams: BatchDeleteCardsPathParameters;data: BatchDeleteCardsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof batchDeleteCards>>, TError,{pathParams: BatchDeleteCardsPathParameters;data: BatchDeleteCardsRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDeleteCards>>, {pathParams: BatchDeleteCardsPathParameters;data: BatchDeleteCardsRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  batchDeleteCards(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteCardsMutationResult = NonNullable<Awaited<ReturnType<typeof batchDeleteCards>>>
    export type BatchDeleteCardsMutationBody = BatchDeleteCardsRequest
    export type BatchDeleteCardsMutationError = BatchDeleteCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Batch Delete Cards
 */
export const useBatchDeleteCards = <TError = BatchDeleteCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteCards>>, TError,{pathParams: BatchDeleteCardsPathParameters;data: BatchDeleteCardsRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof batchDeleteCards>>,
        TError,
        {pathParams: BatchDeleteCardsPathParameters;data: BatchDeleteCardsRequest},
        TContext
      > => {

      const mutationOptions = getBatchDeleteCardsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Card
 */
export const getCard = (
    { stackId, cardId }: GetCardPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getCardMutator<CardWithStackResponseOutput>(
      {url: `/stackie/stacks/${stackId}/cards/${cardId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCardQueryKey = ({ stackId, cardId }: GetCardPathParameters,) => {
    return [`/stackie/stacks/${stackId}/cards/${cardId}`] as const;
    }

    
export const getGetCardQueryOptions = <TData = Awaited<ReturnType<typeof getCard>>, TError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId, cardId }: GetCardPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCardQueryKey({ stackId, cardId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCard>>> = ({ signal }) => getCard({ stackId, cardId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCardQueryResult = NonNullable<Awaited<ReturnType<typeof getCard>>>
export type GetCardQueryError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Get Card
 */
export const useGetCard = <TData = Awaited<ReturnType<typeof getCard>>, TError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId, cardId }: GetCardPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCardQueryOptions({ stackId, cardId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCardSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCard>>, TError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>({ stackId, cardId }: GetCardPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCardQueryKey({ stackId, cardId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCard>>> = ({ signal }) => getCard({ stackId, cardId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCardSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCard>>>
export type GetCardSuspenseQueryError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Get Card
 */
export const useGetCardSuspense = <TData = Awaited<ReturnType<typeof getCard>>, TError = GetCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 { stackId, cardId }: GetCardPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCard>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCardSuspenseQueryOptions({ stackId, cardId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Edit Card
 */
export const editCard = (
    { stackId, cardId }: EditCardPathParameters,
    editCardRequest: EditCardRequest,
 ) => {
      
      
      return editCardMutator<CardWithStackResponseOutput>(
      {url: `/stackie/stacks/${stackId}/cards/${cardId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: editCardRequest
    },
      );
    }
  


export const getEditCardMutationOptions = <TError = EditCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editCard>>, TError,{pathParams: EditCardPathParameters;data: EditCardRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof editCard>>, TError,{pathParams: EditCardPathParameters;data: EditCardRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editCard>>, {pathParams: EditCardPathParameters;data: EditCardRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  editCard(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditCardMutationResult = NonNullable<Awaited<ReturnType<typeof editCard>>>
    export type EditCardMutationBody = EditCardRequest
    export type EditCardMutationError = EditCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>

    /**
 * @summary Edit Card
 */
export const useEditCard = <TError = EditCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | HTTPValidationError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editCard>>, TError,{pathParams: EditCardPathParameters;data: EditCardRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof editCard>>,
        TError,
        {pathParams: EditCardPathParameters;data: EditCardRequest},
        TContext
      > => {

      const mutationOptions = getEditCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Card
 */
export const deleteCard = (
    { stackId, cardId }: DeleteCardPathParameters,
    deleteCardRequest: DeleteCardRequest,
 ) => {
      
      
      return deleteCardMutator<unknown>(
      {url: `/stackie/stacks/${stackId}/cards/${cardId}`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteCardRequest
    },
      );
    }
  


export const getDeleteCardMutationOptions = <TError = DeleteCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{pathParams: DeleteCardPathParameters;data: DeleteCardRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{pathParams: DeleteCardPathParameters;data: DeleteCardRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCard>>, {pathParams: DeleteCardPathParameters;data: DeleteCardRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  deleteCard(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCard>>>
    export type DeleteCardMutationBody = DeleteCardRequest
    export type DeleteCardMutationError = DeleteCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Delete Card
 */
export const useDeleteCard = <TError = DeleteCardErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{pathParams: DeleteCardPathParameters;data: DeleteCardRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCard>>,
        TError,
        {pathParams: DeleteCardPathParameters;data: DeleteCardRequest},
        TContext
      > => {

      const mutationOptions = getDeleteCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Change Card Stack
 */
export const changeCardStack = (
    changeCardRequest: ChangeCardRequest,
 ) => {
      
      
      return changeCardStackMutator<CardWithStackResponseOutput>(
      {url: `/stackie/change_card_stack`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeCardRequest
    },
      );
    }
  


export const getChangeCardStackMutationOptions = <TError = ChangeCardStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCardStack>>, TError,{data: ChangeCardRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof changeCardStack>>, TError,{data: ChangeCardRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeCardStack>>, {data: ChangeCardRequest}> = (props) => {
          const {data} = props ?? {};

          return  changeCardStack(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeCardStackMutationResult = NonNullable<Awaited<ReturnType<typeof changeCardStack>>>
    export type ChangeCardStackMutationBody = ChangeCardRequest
    export type ChangeCardStackMutationError = ChangeCardStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Change Card Stack
 */
export const useChangeCardStack = <TError = ChangeCardStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeCardStack>>, TError,{data: ChangeCardRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof changeCardStack>>,
        TError,
        {data: ChangeCardRequest},
        TContext
      > => {

      const mutationOptions = getChangeCardStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Move Cards
 */
export const batchMoveCards = (
    { stackId }: BatchMoveCardsPathParameters,
    moveCardsRequest: MoveCardsRequest,
 ) => {
      
      
      return batchMoveCardsMutator<CardWithStackResponseOutput[]>(
      {url: `/stackie/stacks/${stackId}/move_cards`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: moveCardsRequest
    },
      );
    }
  


export const getBatchMoveCardsMutationOptions = <TError = BatchMoveCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchMoveCards>>, TError,{pathParams: BatchMoveCardsPathParameters;data: MoveCardsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof batchMoveCards>>, TError,{pathParams: BatchMoveCardsPathParameters;data: MoveCardsRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchMoveCards>>, {pathParams: BatchMoveCardsPathParameters;data: MoveCardsRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  batchMoveCards(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchMoveCardsMutationResult = NonNullable<Awaited<ReturnType<typeof batchMoveCards>>>
    export type BatchMoveCardsMutationBody = MoveCardsRequest
    export type BatchMoveCardsMutationError = BatchMoveCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Batch Move Cards
 */
export const useBatchMoveCards = <TError = BatchMoveCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchMoveCards>>, TError,{pathParams: BatchMoveCardsPathParameters;data: MoveCardsRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof batchMoveCards>>,
        TError,
        {pathParams: BatchMoveCardsPathParameters;data: MoveCardsRequest},
        TContext
      > => {

      const mutationOptions = getBatchMoveCardsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Search Cards
 */
export const searchCards = (
    params: SearchCardsParams,
 signal?: AbortSignal
) => {
      
      
      return searchCardsMutator<SearchCardsResponse>(
      {url: `/stackie/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSearchCardsQueryKey = (params: SearchCardsParams,) => {
    return [`/stackie/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchCardsQueryOptions = <TData = Awaited<ReturnType<typeof searchCards>>, TError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params: SearchCardsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchCardsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchCards>>> = ({ signal }) => searchCards(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchCardsQueryResult = NonNullable<Awaited<ReturnType<typeof searchCards>>>
export type SearchCardsQueryError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Search Cards
 */
export const useSearchCards = <TData = Awaited<ReturnType<typeof searchCards>>, TError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params: SearchCardsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchCardsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSearchCardsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof searchCards>>, TError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(params: SearchCardsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchCardsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchCards>>> = ({ signal }) => searchCards(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchCardsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof searchCards>>>
export type SearchCardsSuspenseQueryError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

/**
 * @summary Search Cards
 */
export const useSearchCardsSuspense = <TData = Awaited<ReturnType<typeof searchCards>>, TError = SearchCardsErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>>(
 params: SearchCardsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchCards>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchCardsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Stackie Send Feedback
 */
export const stackieSendFeedback = (
    sendStackieFeedbackRequest: SendStackieFeedbackRequest,
 ) => {
      
      
      return stackieSendFeedbackMutator<unknown>(
      {url: `/stackie/feedback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendStackieFeedbackRequest
    },
      );
    }
  


export const getStackieSendFeedbackMutationOptions = <TError = StackieSendFeedbackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stackieSendFeedback>>, TError,{data: SendStackieFeedbackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof stackieSendFeedback>>, TError,{data: SendStackieFeedbackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof stackieSendFeedback>>, {data: SendStackieFeedbackRequest}> = (props) => {
          const {data} = props ?? {};

          return  stackieSendFeedback(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StackieSendFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof stackieSendFeedback>>>
    export type StackieSendFeedbackMutationBody = SendStackieFeedbackRequest
    export type StackieSendFeedbackMutationError = StackieSendFeedbackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Stackie Send Feedback
 */
export const useStackieSendFeedback = <TError = StackieSendFeedbackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stackieSendFeedback>>, TError,{data: SendStackieFeedbackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof stackieSendFeedback>>,
        TError,
        {data: SendStackieFeedbackRequest},
        TContext
      > => {

      const mutationOptions = getStackieSendFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Generate Turn Into Stack Draft Stack Examples
 */
export const generateTurnIntoStackDraftStackExamples = (
    { stackId }: GenerateTurnIntoStackDraftStackExamplesPathParameters,
    turnIntoStackDraftExamplesRequest: TurnIntoStackDraftExamplesRequest,
 ) => {
      
      
      return generateTurnIntoStackDraftStackExamplesMutator<StackieDraftExample[]>(
      {url: `/stackie/stacks/${stackId}/turn_into_stack/draft/examples`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: turnIntoStackDraftExamplesRequest
    },
      );
    }
  


export const getGenerateTurnIntoStackDraftStackExamplesMutationOptions = <TError = GenerateTurnIntoStackDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>, TError,{pathParams: GenerateTurnIntoStackDraftStackExamplesPathParameters;data: TurnIntoStackDraftExamplesRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>, TError,{pathParams: GenerateTurnIntoStackDraftStackExamplesPathParameters;data: TurnIntoStackDraftExamplesRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>, {pathParams: GenerateTurnIntoStackDraftStackExamplesPathParameters;data: TurnIntoStackDraftExamplesRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  generateTurnIntoStackDraftStackExamples(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GenerateTurnIntoStackDraftStackExamplesMutationResult = NonNullable<Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>>
    export type GenerateTurnIntoStackDraftStackExamplesMutationBody = TurnIntoStackDraftExamplesRequest
    export type GenerateTurnIntoStackDraftStackExamplesMutationError = GenerateTurnIntoStackDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Generate Turn Into Stack Draft Stack Examples
 */
export const useGenerateTurnIntoStackDraftStackExamples = <TError = GenerateTurnIntoStackDraftStackExamplesErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>, TError,{pathParams: GenerateTurnIntoStackDraftStackExamplesPathParameters;data: TurnIntoStackDraftExamplesRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof generateTurnIntoStackDraftStackExamples>>,
        TError,
        {pathParams: GenerateTurnIntoStackDraftStackExamplesPathParameters;data: TurnIntoStackDraftExamplesRequest},
        TContext
      > => {

      const mutationOptions = getGenerateTurnIntoStackDraftStackExamplesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Turn Into Stack Draft
 */
export const turnIntoStackDraft = (
    { stackId }: TurnIntoStackDraftPathParameters,
    turnIntoStackDraftRequest: TurnIntoStackDraftRequest,
 ) => {
      
      
      return turnIntoStackDraftMutator<TurnIntoStackDraftResponse>(
      {url: `/stackie/stacks/${stackId}/turn_into_stack/draft`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: turnIntoStackDraftRequest
    },
      );
    }
  


export const getTurnIntoStackDraftMutationOptions = <TError = TurnIntoStackDraftErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof turnIntoStackDraft>>, TError,{pathParams: TurnIntoStackDraftPathParameters;data: TurnIntoStackDraftRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof turnIntoStackDraft>>, TError,{pathParams: TurnIntoStackDraftPathParameters;data: TurnIntoStackDraftRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof turnIntoStackDraft>>, {pathParams: TurnIntoStackDraftPathParameters;data: TurnIntoStackDraftRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  turnIntoStackDraft(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TurnIntoStackDraftMutationResult = NonNullable<Awaited<ReturnType<typeof turnIntoStackDraft>>>
    export type TurnIntoStackDraftMutationBody = TurnIntoStackDraftRequest
    export type TurnIntoStackDraftMutationError = TurnIntoStackDraftErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Turn Into Stack Draft
 */
export const useTurnIntoStackDraft = <TError = TurnIntoStackDraftErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof turnIntoStackDraft>>, TError,{pathParams: TurnIntoStackDraftPathParameters;data: TurnIntoStackDraftRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof turnIntoStackDraft>>,
        TError,
        {pathParams: TurnIntoStackDraftPathParameters;data: TurnIntoStackDraftRequest},
        TContext
      > => {

      const mutationOptions = getTurnIntoStackDraftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Turn Into Stack
 */
export const turnIntoStack = (
    { stackId }: TurnIntoStackPathParameters,
    turnIntoStackRequest: TurnIntoStackRequest,
 ) => {
      
      
      return turnIntoStackMutator<TurnIntoStackResponse>(
      {url: `/stackie/stacks/${stackId}/turn_into_stack`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: turnIntoStackRequest
    },
      );
    }
  


export const getTurnIntoStackMutationOptions = <TError = TurnIntoStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof turnIntoStack>>, TError,{pathParams: TurnIntoStackPathParameters;data: TurnIntoStackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof turnIntoStack>>, TError,{pathParams: TurnIntoStackPathParameters;data: TurnIntoStackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof turnIntoStack>>, {pathParams: TurnIntoStackPathParameters;data: TurnIntoStackRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  turnIntoStack(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TurnIntoStackMutationResult = NonNullable<Awaited<ReturnType<typeof turnIntoStack>>>
    export type TurnIntoStackMutationBody = TurnIntoStackRequest
    export type TurnIntoStackMutationError = TurnIntoStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>

    /**
 * @summary Turn Into Stack
 */
export const useTurnIntoStack = <TError = TurnIntoStackErrorType<AppRequestValidationError | AppRequestUnauthorizedError | AppRequestPermissionDeniedError | AppSystemUnknownError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof turnIntoStack>>, TError,{pathParams: TurnIntoStackPathParameters;data: TurnIntoStackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof turnIntoStack>>,
        TError,
        {pathParams: TurnIntoStackPathParameters;data: TurnIntoStackRequest},
        TContext
      > => {

      const mutationOptions = getTurnIntoStackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
