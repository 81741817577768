const iconTypes = {
  "date-time": {
    type: "date-time",
    icon: "Calendar",
    name: "DateTime",
  },
  number: {
    type: "number",
    icon: "Number",
    name: "Number",
  },
  boolean: {
    type: "boolean",
    icon: "Complete",
    name: "Boolean",
  },
  text: {
    type: "text",
    icon: "Text",
    name: "Text",
  },
};

export default iconTypes;
