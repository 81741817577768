import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import ContentSafeArea from "@/app/components/content-safe-area";
import ErrorBoundaryWithReload from "@/app/components/error-boundary-with-reload";

export default function NewStackLayout() {
  return (
    <div className="min-h-[100svh] bg-[#f2f2f2]">
      <ContentSafeArea>
        <ErrorBoundaryWithReload>
          <Suspense>
            <Outlet />
          </Suspense>
        </ErrorBoundaryWithReload>
      </ContentSafeArea>
    </div>
  );
}
