import useStackParams from "../../../params";
import useCardParams from "../params";
import CardEditor from "./components/card-editor";

export default function EditCardPage() {
  const { stackId } = useStackParams();
  const { cardId } = useCardParams();

  return (
    <div className="py-[10px]">
      <CardEditor stackId={stackId} cardId={cardId} />
    </div>
  );
}
