import { createContext, useContext, useEffect, useState } from "react";

import { useJSBridge } from ".";

const AccessTokenContext = createContext<string>(undefined!);

export function useAccessToken() {
  return useContext(AccessTokenContext);
}

export default function AccessTokenProvider({ children }: { children: React.ReactNode }) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"));

  const jsBridge = useJSBridge();
  useEffect(() => {
    (async () => {
      if (!jsBridge) {
        return;
      }

      const token = await jsBridge.getUserToken();
      if (token && token !== accessToken) {
        localStorage.setItem("access_token", token);
        setAccessToken(token);
      }
    })();
  }, [jsBridge, accessToken]);

  if (!accessToken) {
    return <div className="grid h-[100svh] place-content-center text-secondary-grey">Signing in...</div>;
  }

  return <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>;
}
