import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useBroadcastChannel } from "use-broadcast-channel";

import { getListStacksQueryKey, useListStacksSuspense } from "@/generated";

import CreateFromTemplates from "../create-from-templates";
import { StackItem } from "./stack-item";

export default function StackGrid() {
  const queryClient = useQueryClient();

  const { data: stacks, refetch } = useListStacksSuspense();

  useBroadcastChannel("stacks:reset-cache", () => {
    queryClient.removeQueries({ exact: false, queryKey: getListStacksQueryKey() });
    refetch();
  });

  if (stacks.length === 1 && stacks[0].total_cards === 0) {
    return <CreateFromTemplates />;
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fill,minmax(160px,1fr))] gap-[14px] px-4">
      <AnimatePresence mode="popLayout" initial={false}>
        {stacks.map((stack) => (
          <motion.div
            key={stack.id}
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, type: "spring" }}
          >
            <StackItem stack={stack} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
