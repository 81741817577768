import { useCallback, useReducer } from "react";

import { StackOutputPropertiesItem } from "@/generated";

interface Stack {
  id: number;
  name: string;
  prompt: string;
  updated_at: string;
  properties: StackOutputPropertiesItem[];
}

const initialState: Stack = {
  id: 0,
  name: "",
  prompt: "",
  properties: [],
  updated_at: "",
};

type Action =
  | { type: "UPDATE_STACK"; payload: Partial<Stack> }
  | { type: "ADD_PROPERTY"; payload: StackOutputPropertiesItem }
  | { type: "UPDATE_PROPERTY"; payload: { id: string; newProperty: StackOutputPropertiesItem } }
  | { type: "UPDATE_PROPERTIES"; payload: StackOutputPropertiesItem[] }
  | { type: "REMOVE_PROPERTY"; payload: string };

function stackReducer(state: Stack, action: Action): Stack {
  switch (action.type) {
    case "UPDATE_STACK":
      return { ...state, ...action.payload };
    case "ADD_PROPERTY":
      return { ...state, properties: [...state.properties, action.payload] };
    case "UPDATE_PROPERTY":
      return {
        ...state,
        properties: state.properties.map((property) =>
          property.id === action.payload.id ? { ...action.payload.newProperty } : property,
        ),
      };
    case "UPDATE_PROPERTIES":
      return { ...state, properties: action.payload };
    case "REMOVE_PROPERTY":
      return {
        ...state,
        properties: state.properties.filter((property) => property.id !== action.payload),
      };
    default:
      return state;
  }
}

export function useStack() {
  const [stack, dispatch] = useReducer(stackReducer, initialState);

  const updateStack = useCallback((updates: Partial<Stack>) => {
    dispatch({ type: "UPDATE_STACK", payload: updates });
  }, []);

  const addProperty = useCallback((property: StackOutputPropertiesItem) => {
    dispatch({ type: "ADD_PROPERTY", payload: property });
  }, []);

  const updateProperty = useCallback((id: string, newProperty: StackOutputPropertiesItem) => {
    dispatch({ type: "UPDATE_PROPERTY", payload: { id, newProperty } });
  }, []);

  const updateProperties = useCallback((newProperties: StackOutputPropertiesItem[]) => {
    dispatch({ type: "UPDATE_PROPERTIES", payload: newProperties });
  }, []);

  const removeProperty = useCallback((id: string) => {
    dispatch({ type: "REMOVE_PROPERTY", payload: id });
  }, []);

  return {
    stack,
    updateStack,
    addProperty,
    updateProperty,
    updateProperties,
    removeProperty,
  };
}
